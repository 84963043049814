import React from "react";
import { PopupModal } from "../_common/PopupModal";
import SubscriptionImage from "../../assets/images/billing/image (2).png";

export function SubscriptionModal({ openPanel, setOpenPanel, userType }) {
  return (
    <div>
      <PopupModal isOpen={openPanel}>
        <div
          className="modal-content"
          style={{ background: "#eff1f4", borderRadius: "10px!important" }}
        >
          <div className="modal-body">
            <p
              style={{
                fontWeight: "bold",
                fontSize: "22px",
                fontFamily: "'Lato',sans-serif",
              }}
            >
              Claim Limit Reached
            </p>
            <p
              style={{
                fontFamily: "Nunito",
                maxWidth: 500,
                textAlign: "center",
              }}
            >
              {userType === "Adjuster" ? (
                <>
                  You cannot add any more claims without a subscription. For
                  unlimited claim files, please request your account`s
                  Superadmin to subscribe now.{" "}
                </>
              ) : (
                <>
                  You cannot add any more claims without a subscription. For
                  unlimited claim files, subscribe now.
                </>
              )}
            </p>
            <div className="row">
              <div className="col-xs-12 text-center">
                <img
                  src={SubscriptionImage}
                  alt="subscription "
                  style={{ width: "300px" }}
                />
              </div>
            </div>
          </div>
          <div
            className="modal-footer text-center"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              borderTop: "none",
              paddingBottom: 60,
            }}
          >
            {userType === "Adjuster" ? (
              <button
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setOpenPanel(false);
                  return false;
                }}
                class="btn btn-warning btn-lg margin-top-10 text-dark"
                style={{ width: "300px" }}
              >
                Close
              </button>
            ) : (
              <>
                <a
                  href="/app/billing"
                  className="btn btn-outline blue-primary"
                  style={{
                    width: "300px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Start Subscription
                </a>
                <a
                  href="!#"
                  onClick={(event) => {
                    event.preventDefault();
                    setOpenPanel(false);
                    return false;
                  }}
                  className="btn btn-link"
                >
                  Not Now
                </a>
              </>
            )}
          </div>
        </div>
      </PopupModal>
    </div>
  );
}
