import React from 'react';
import {useSelector} from 'react-redux';
import SelectSearch from 'react-select-search';

import {Form} from '../../../../_common/form/Form';
import {Col, Row} from '../../../../_common/layout/Layout';
import {PhoneNumberMask} from '../../../../_common/MaskedInput';

import './ClaimAdjuster.css';
import '../../../../_common/SingleSelectDropDown.css';

function DeleteClaimAdjuster({onDeleteClick, isDeleting, isSharedView}) {
  if (isSharedView) {
    return null;
  }

  return (
    <button className="btn btn-danger" style={{marginTop: '10px'}} type="button" onClick={onDeleteClick}
            disabled={isDeleting}>
      <i className="fa fa-close"/> Delete
    </button>
  );
}

export function ClaimAdjuster({title, adjuster, onAdjusterChange, isShowDelete, onDeleteClick, dataTestIdPrefix}) {
  const insurers = useSelector((state) => state.insurers.list);
  const {isSharedView} = useSelector((state) => state.claims.claim);

  const {isDeleting} = useSelector((state) => state.claims.claim.claimAdjusters);

  const selectableInsurers = insurers?.map((insurer) => {
    const {name, id, type} = insurer || {};
    return {name, value: JSON.stringify({id, type})};
  });

  function handleInsurerChange(value) {
    const {id, type} = JSON.parse(value);
    const insurer = insurers.find(
      (insurer) => insurer.id === id && insurer.type === type
    );

    onAdjusterChange('insurer', {id: insurer?.id, type: insurer?.type});
    onAdjusterChange('phone', insurer?.phone);
    onAdjusterChange('phoneExt', insurer?.extension);
    onAdjusterChange('mobile', insurer?.mobile);
    onAdjusterChange('email', insurer?.email);
    onAdjusterChange('secondaryEmail', insurer?.secondaryEmail);
  }

  const {insurer} = adjuster || {};
  const insurerSelectValue = JSON.stringify({id: insurer?.id, type: insurer?.type});
  const isAdjFieldsDisabled = !insurer?.id || isSharedView;
  return (
    <div className="mt-element-ribbon claim__adjuster" data-testid={`adjuster-box-${dataTestIdPrefix}`}>
      <div className="ribbon uppercase light ribbon--light">{title}</div>
      <div className="mt-repeater-input pull-right">
        {isShowDelete && (
          <DeleteClaimAdjuster isDeleting={isDeleting} isSharedView={isSharedView} onDeleteClick={onDeleteClick}/>
        )}
      </div>

      <div className="ribbon-content">
        <Form.Group>
          <Row>
            <Col md={6}>
              <Form.Label>Insurance Company</Form.Label>

              <SelectSearch className={`select-search adjuster-insurer-${dataTestIdPrefix}`} name="language"
                            value={insurerSelectValue} options={selectableInsurers} placeholder={'Insurance Company'}
                            search={true} autoComplete={'off'} onChange={handleInsurerChange}/>
            </Col>
            <Col md={6}>
              <Form.Label>Insurance Adjuster Name</Form.Label>
              <Form.Input type="text" value={adjuster?.name || ''} placeholder="Insurance Adjuster Name"
                          disabled={isAdjFieldsDisabled} data-testid={`adjuster-name-${dataTestIdPrefix}`}
                          onChange={(e) => onAdjusterChange('name', e.target.value)}/>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col md={6}>
              <Form.Label>Insurance Adjuster Phone</Form.Label>

              <PhoneNumberMask inputClass="form-control col-md-5" customStyle={{width: '80%'}} value={adjuster?.phone}
                               placeholderText="Insurance Adjuster Phone" disabled={isAdjFieldsDisabled}
                               data-testid={`adjuster-phone-${dataTestIdPrefix}`}
                               handleChange={(e) => onAdjusterChange('phone', e.target.value)}/>
              <Form.Input style={{width: '19%'}} type="text" value={adjuster?.phoneExt || ''} placeholder="Ext"
                          disabled={isAdjFieldsDisabled} data-testid={`adjuster-phoneExt-${dataTestIdPrefix}`}
                          onChange={(e) => onAdjusterChange('phoneExt', e.target.value)}/>
            </Col>
            <Col md={6}>
              <Form.Label>Insurance Adjuster Mobile</Form.Label>
              <PhoneNumberMask value={adjuster?.mobile} placeholderText="Insurance Adjuster Mobile"
                               disabled={isAdjFieldsDisabled} data-testid={`adjuster-mobile-${dataTestIdPrefix}`}
                               handleChange={(e) => onAdjusterChange('mobile', e.target.value)}/>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col md={6}>
              <Form.Label>Insurance Adjuster Email</Form.Label>
              <Form.Input type="text" value={adjuster?.email || ''} placeholder="Insurance Adjuster Email"
                          disabled={isAdjFieldsDisabled} data-testid={`adjuster-email-${dataTestIdPrefix}`}
                          onChange={(e) => onAdjusterChange('email', e.target.value)}/>
            </Col>
            <Col md={6}>
              <Form.Label>Insurance Adjuster Secondary Email</Form.Label>
              <Form.Input type="text" value={adjuster?.secondaryEmail || ''}
                          placeholder="Insurance Adjuster Secondary Email" disabled={isAdjFieldsDisabled}
                          data-testid={`adjuster-secondary-email-${dataTestIdPrefix}`}
                          onChange={(e) => onAdjusterChange('secondaryEmail', e.target.value)}/>
            </Col>
          </Row>
        </Form.Group>
      </div>
    </div>
  );
}
