import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { Dropdown } from "../../../_common/Dropdown";
import { AttachmentFolders } from "./AttachmentsFolders";
import { useShareAttachmentsHook } from "./ShareAttachmentHook";
import { ErrorMessage } from "../../../_common/Sweetalert";
import { Notify } from "../../../../services/Notify.service";
const claimRoutePaths = {
  shareView: "/shared-claim/:shareToken",
  new: "/claims/new",
  edit: "/claims/:id",
};

export const AttachmentsTab = ({ claimId }) => {
  const { path } = useRouteMatch();
  const isSharedView = path === claimRoutePaths.shareView;

  const [selectedFolderId, setSelectedFolderId] = useState(1);
  const [selectedFilesId, setSelectedFilesId] = useState([]);
  const [isShowShareUrl, setIsShowShareUrl] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [isShowSweetAlertError, setIsShowSweetAlertError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const disableHandler = () => {
    setIsShowSweetAlertError(false);
  };

  const {
    shareAll,
    shareSelected,
    shareSelectedFolder,
  } = useShareAttachmentsHook({
    claimId,
    selectedFolderId,
    selectedFilesId,
    setErrorMessage,
    setIsShowSweetAlertError,
    setShareUrl,
    setIsShowShareUrl,
    isSharedView,
  });

  const copyOnClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    Notify.success("Copy to clipboard.");
  };

  return (
    <div>
      {isShowSweetAlertError && (
        <ErrorMessage
          show={isShowSweetAlertError}
          clickHandler={(e) => disableHandler(e)}
          msg={errorMessage}
        />
      )}
      {isShowShareUrl && (
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="well">
              <div className="caption">
                <h3>Share URL</h3>
                <p>{shareUrl}</p>
                <p>
                  <button
                    className="btn blue"
                    onClick={() => copyOnClipboard()}
                  >
                    Copy
                  </button>
                  <button
                    className="btn default"
                    onClick={() => setIsShowShareUrl(false)}
                  >
                    Cancel
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="portlet light">
        <div className="portlet-title">
          <div className="caption">
            <i className="icon-folder"></i>Attachments
          </div>
          <div className="actions">
            <Dropdown
              title="Share Attachments"
              variant="blue-primary bold "
              isNotOutline={true}
            >
              <Dropdown.ListItem onClick={() => shareAll()}>
                Share All Attachments
              </Dropdown.ListItem>
              <Dropdown.ListItem onClick={() => shareSelected()}>
                Share Selected Files
              </Dropdown.ListItem>

              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "1",
                  })
                }
              >
                Share Documents
              </Dropdown.ListItem>
              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "2",
                  })
                }
              >
                Share Estimates
              </Dropdown.ListItem>
              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "3",
                  })
                }
              >
                Share Photos
              </Dropdown.ListItem>
              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "4",
                  })
                }
              >
                Share Reports
              </Dropdown.ListItem>
              <Dropdown.ListItem
                onClick={() =>
                  shareSelectedFolder({
                    folderId: "5",
                  })
                }
              >
                Share Email
              </Dropdown.ListItem>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="portlet-body">
        <AttachmentFolders
          claimId={claimId}
          setSelectedFolderId={setSelectedFolderId}
          selectedFilesId={selectedFilesId}
          setSelectedFilesId={setSelectedFilesId}
          isSharedView={isSharedView}
        />
      </div>
    </div>
  );
};
