import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { Error } from "../_common/Error";
import { FullPageSpinner } from "../_common/Spinner";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        type="search"
        className="form-control input-sm input-small input-inline"
      />
    </span>
  );
}

function AdminDatatable({
  admins,
  isLoading,
  error,
  setOpensidePanel,
  setAdminDetails,
  confirmDelete,
}) {
  const columns = [
    {
      Header: "Admin Name",
      accessor: "name",
      sortType: "basic",
    },
    {
      Header: "Email Address",
      accessor: "email",
      sortType: "basic",
    },
    {
      Header: "Phone #",
      accessor: "phone",
      sortType: "basic",
    },
    {
      Header: "Extension",
      accessor: "phoneExt",
      sortType: "basic",
    },
    {
      Header: "Fax #",
      accessor: "fax",
      sortType: "basic",
    },
    {
      Header: "Status",
      accessor: "status",
      sortType: "basic",
    },
    {
      Header: "Action",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div data-testid={`admin-actions-${row?.original?.id}`}>
          <a
            className="edit"
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              setOpensidePanel(true);
              setAdminDetails(row.original);
            }}
            style={{
              marginRight: "8px",
              color: "#1bbc9b",
            }}
            data-testid="edit-admin"
          >
            <i className="fa fa-pencil"></i>
          </a>
          <a
            className="delete"
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              confirmDelete(row.original);
            }}
            style={{ color: "#e7505a" }}
            data-testid="delete-admin"
          >
            <i className="fa fa-trash-o"></i>
          </a>
        </div>
      ),
      editRow({ id }) {
        console.log(id);
        return false;
      },
    },
  ];
  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (error) {
    return <Error message={error} />;
  }

  const hasAdmins = admins && admins.length;
  if (!hasAdmins) {
    return (
      <div>
        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
          All your Admins will show here. Use the 'Add New +' button to add an
          Admin.
        </h6>
      </div>
    );
  }

  return <AdminsTable adminsData={admins} columns={columns} />;
}

function AdminsTable({ adminsData, columns, updateMyData }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => adminsData, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      initialState: {
        sortBy: [
          {
            id: "name",
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className="dataTables_wrapper no-footer">
      <div className="dataTables_filter">
        <label>
          Search:
          {
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          }
        </label>
      </div>
      <table
        id="Admin-Data-Table"
        className="table table-striped table-hover table-bordered dataTable no-footer"
        {...getTableProps()}
        data-testid="admins-table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={
                    !column.disableSortBy
                      ? column.isSorted
                        ? column.isSortedDesc
                          ? "sorting_desc"
                          : " sorting_asc"
                        : "sorting"
                      : ""
                  }
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export { AdminDatatable };
