import React, {useMemo, useState} from 'react';
import {useAsyncDebounce, useGlobalFilter, useSortBy, useTable} from 'react-table';
import Moment from 'moment';
import {Error} from '../_common/Error';
import {Spinner} from '../_common/Spinner';

function GlobalFilter({globalFilter, setGlobalFilter}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {setGlobalFilter(value || undefined);}, 200);

  return (
    <span>
      <input className="form-control input-sm input-small input-inline" type="search" value={value || ''}
             onChange={(e) => {
               setValue(e.target.value);
               onChange(e.target.value);
             }}
      />
    </span>
  );
}

const DATE_FORMAT = 'MMM DD YYYY HH:MM A';

function TemplatesDocDatatable(
  {documents, isLoading, error, confirmDelete, getFileName}
) {
  const columns = [
    {
      Header: 'Name', accessor: 'name', sortType: 'basic',
      Cell: ({row}) => (
        <span>
          {row.original.name === ''
            ? getFileName(row.original.fileName)
            : row.original.name}
        </span>
      )
    },
    {Header: 'Created By', accessor: 'createdByName', sortType: 'basic'},
    {
      Header: 'Last Modified', accessor: 'updatedAt', sortType: 'basic',
      Cell: ({row}) => (
        <span>
          {Moment(row.original.updatedAt).format(DATE_FORMAT)}
        </span>
      )
    },
    {
      Header: 'Action', accessor: 'id', disableSortBy: true,
      Cell: ({row}) => (
        <div>
          <a className="delete" style={{color: '#e7505a'}} href="#!"
             onClick={(e) => {
               e.preventDefault();
               confirmDelete(row.original);
             }}
          >
            <i className="fa fa-trash-o"/>
          </a>
        </div>
      )
    }
  ];
  if (isLoading) {
    return <Spinner/>;
  }

  if (error) {
    return <Error message={error}/>;
  }

  const hadDocument = documents && documents.length;
  if (!hadDocument) {
    return (
      <div>
        <h6 style={{textAlign: 'center', fontWeight: 'bold'}}>
          All your Templates will show here. Use the 'Upload New Template +'
          button to upload a new template.
        </h6>
      </div>
    );
  }

  return <DocumentsTable documentData={documents} columns={columns}/>;
}

function DocumentsTable({documentData, columns, updateMyData}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => documentData, []);
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter
  } = useTable(
    {columns, data, updateMyData, initialState: {sortBy: [{id: 'name', desc: false}]}},
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className="dataTables_wrapper no-footer">
      <div className="dataTables_filter">
        <label>
          Search:
          {
            <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter}/>
          }
        </label>
      </div>
      <table id="Admin-Data-Table"
             className="table table-striped table-hover table-bordered dataTable no-footer"
             {...getTableProps()}
      >
        <thead>
        {
          headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                let headerClassName = '';
                if (!column.disableSortBy) {
                  headerClassName = 'sorting';
                  if (column.isSorted) {
                    headerClassName = ' sorting_asc';
                    if (column.isSortedDesc) {
                      headerClassName = 'sorting_desc';
                    }
                  }
                }
                return (
                  <th className={headerClassName}{...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))
        }
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                );
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
    </div>
  );
}

export {TemplatesDocDatatable};
