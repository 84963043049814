import React, { useState } from "react";
import { PopupModal } from "../_common/PopupModal";
import { SuccessMessage } from "../_common/Sweetalert";
import * as Api from "../../Api";

export function CancelSubscription({
  openPanel,
  setOpenPanel,
  reloadHistory,
  reloadStatus,
  reloadPaymentMethod,
}) {
  const [checked, setChecked] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleCheckbox = (event) => {
    if (event.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  const CancelSubscription = () => {
    Api.Billing.cancleSubscription()
      .then(() => {
        setChecked(false);
        setShowSuccessMessage(true);
        reloadHistory();
        reloadStatus();
        reloadPaymentMethod();
        setOpenPanel(false);
        setSuccessMessage("Cancelled Successfully! ");
      })
      .catch(() => {
        setChecked(false);
        setOpenPanel(false);
      });
  };

  return (
    <div>
      <SuccessMessage
        show={showSuccessMessage}
        clickHandler={setShowSuccessMessage}
        message={successMessage}
      />
      <PopupModal isOpen={openPanel}>
        <div className="modal-dialog" role="document" style={{ margin: "0px" }}>
          <div
            className="modal-content"
            style={{ borderRadius: "14px!important" }}
          >
            <div
              className="modal-body"
              style={{
                padding: "13px 50px 36px 50px!important",
                background: "#ECEEF1",
                borderRadius: "14px!important",
              }}
            >
              <h3
                style={{
                  fontWeight: "600!important",
                  marginTop: "21px",
                  color: "#28323F",
                }}
              >
                Cancel Subscription
              </h3>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2rem",
                  color: "#28323F",
                  fontFamily: "Nunito",
                }}
              >
                Are you sure you want to cancel your subscription?
              </p>
              <p style={{ fontSize: "1.7rem", fontFamily: "Nunito" }}>
                Your account will be reverted to a FREE account and you will be
                limited to only{" "}
                <span className="font-red-mint"> 3 Claim Files</span>.
              </p>
              <div
                className="mt-checkbox-inline"
                style={{ fontFamily: "Nunito", fontSize: "0.7rem" }}
              >
                <label
                  className="mt-checkbox"
                  style={{
                    fontSize: "1.7rem",
                    paddingTop: "0px",
                    lineHeight: "1.3",
                  }}
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    value="true"
                    id="cancel-subscription-check-box"
                  />
                  I understand I will be limited to 3 Claim Files.<span></span>
                </label>
              </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                {checked ? (
                  <button
                    onClick={() => {
                      CancelSubscription();
                    }}
                    className="btn red-mint"
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "2rem",
                      width: "350px",
                      borderRadius: "5px!important",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      fontFamily: "Nunito",
                    }}
                  >
                    Cancel Subscription
                  </button>
                ) : (
                  <button
                    disabled
                    className="btn red-mint"
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "2rem",
                      width: "350px",
                      borderRadius: "5px!important",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      fontFamily: "Nunito",
                    }}
                  >
                    Cancel Subscription
                  </button>
                )}
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "15px",
                }}
              >
                <button
                  onClick={() => {
                    setOpenPanel(false);
                    setChecked(false);
                  }}
                  className="btn default"
                  data-dismiss="modal"
                  style={{
                    fontWeight: "600",
                    fontSize: "2rem",
                    width: "350px",
                    borderRadius: "5px!important",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    fontFamily: "Nunito",
                    marginTop: "0px",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </PopupModal>
    </div>
  );
}
