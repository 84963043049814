import React, { useState, useEffect } from "react";
import { SlidingSidePanel } from "../_common/SlidingSidePanel";
import * as Api from "../../Api";
import { Error } from "../_common/Error";
import { Notify } from "../../services/Notify.service";
import { CustomFieldsOptions } from "./CustomFieldsOptions";
import { SubmitButton } from "../profile/SubmitButton";

export function AddAndEdit({
  openPanel,
  setOpenPanel,
  customFieldDetails,
  reloadTable,
}) {
  const [fieldName, setFieldName] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [autopull, setAutopull] = useState(false);

  const [autopullFrom, setAutopullFrom] = useState("adjuster_names");

  const [autopullOptions, setAutopullOptions] = useState([
    {
      optionText: "",
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCheckbox = (event) => {
    if (event.target.checked) {
      setAutopull(true);
    }
    if (!event.target.checked) {
      setAutopull(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (fieldName === "" || typeof fieldName === "undefined") {
      setError("Name is Required.");
      return "";
    }
    if (fieldType === "" || typeof fieldType === "undefined") {
      setError("Type is Required.");
      return "";
    }
    setError("");
    setIsLoading(true);
    const requestData = {
      name: fieldName,
      type: fieldType,
      autopull,
      autopullFrom,
      options: autopullOptions,
    };

    if (customFieldDetails.id) {
      return Api.CustomFields.edit({
        id: customFieldDetails.id,
        requestData,
      })
        .then((data) => {
          setOpenPanel(false);
          reloadTable();
          setFieldName("");
          setFieldType("");
          setAutopull("");
          setAutopullFrom("");
          setAutopullOptions([
            {
              optionText: "",
            },
          ]);
          Notify.success("Custom field added successfully!");
        })
        .catch((err) => {
          Api.getAndShowError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return Api.CustomFields.add({
      requestData,
    })
      .then((data) => {
        setOpenPanel(false);
        reloadTable();
        setFieldName("");
        setFieldType("");
        setAutopull("");
        setAutopullFrom("");
        setAutopullOptions([
          {
            optionText: "",
          },
        ]);
        Notify.success("Custom field added successfully!");
      })
      .catch((err) => {
        Api.getAndShowError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setFieldName(customFieldDetails ? customFieldDetails.displayName : "");
    setFieldType(customFieldDetails ? customFieldDetails.fieldType : "");
    setAutopull(customFieldDetails ? customFieldDetails.autopull : false);
    setAutopullFrom(
      customFieldDetails ? customFieldDetails.autopullFrom : false
    );
    if (customFieldDetails && !customFieldDetails.autopull) {
      setAutopullOptions(customFieldDetails.options);
    }
    if (
      (customFieldDetails && customFieldDetails.autopull) ||
      typeof customFieldDetails.autopull === "undefined"
    ) {
      setAutopullOptions([
        {
          optionText: "",
        },
      ]);
    }
  }, [customFieldDetails]);
  return (
    <div>
      <SlidingSidePanel type={"right"} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-sdark uppercase">
                {customFieldDetails.id
                  ? "Edit Custom Field"
                  : "Add New Custom Field"}
              </span>
            </div>
            <div className="tools">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpenPanel(false);
                }}
                href="#!"
                className="remove"
                data-original-title=""
                title=""
              >
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            <form onSubmit={handleSubmit} method="post">
              {error ? <Error message={error} /> : ""}
              <div className="form-group">
                <label className="control-label">Field Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={fieldName}
                  onChange={(e) => setFieldName(e.target.value)}
                  placeholder="Field Name"
                />
              </div>
              <div className="form-group">
                <label className="control-label">Field Type</label>
                <select
                  className="form-control"
                  value={fieldType}
                  onChange={(e) => setFieldType(e.target.value)}
                >
                  <option value="">Field Type</option>
                  <option value="textbox">Textbox</option>
                  <option value="date">Date</option>
                  <option value="time">Time</option>
                  <option value="dropdown">Dropdown</option>
                  <option value="checkbox">Checkbox</option>
                </select>
              </div>
              {(fieldType === "dropdown" || fieldType === "checkbox") && (
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      value={autopull}
                      checked={autopull}
                      onChange={(e) => handleCheckbox(e)}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;Autopull From
                  </label>
                </div>
              )}
              {(fieldType === "dropdown" || fieldType === "checkbox") &&
              autopull ? (
                <div className="form-group">
                  <label className="control-label">Autopull From Field</label>
                  <select
                    className="form-control"
                    value={autopullFrom}
                    onChange={(e) => setAutopullFrom(e.target.value)}
                  >
                    <option value="">Select Autopull From Field</option>
                    <option value="adjuster_names" selected="selected">
                      Adjuster Names
                    </option>
                    <option value="attorney_names">Attorney Names</option>
                    <option value="insurer_names">Insurer Names</option>
                    <option value="vendor_names">Vendor Names</option>
                  </select>
                </div>
              ) : (
                ""
              )}
              {(fieldType === "dropdown" || fieldType === "checkbox") &&
              !autopull ? (
                <CustomFieldsOptions
                  autopullOptions={autopullOptions}
                  setAutopullOptions={setAutopullOptions}
                />
              ) : (
                ""
              )}
              <div className="form-actions">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <SubmitButton isLoading={isLoading} />

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenPanel(false);
                        setError("");
                      }}
                      type="button"
                      className="btn default"
                      name="cancel"
                      value="cancel"
                      style={{ margin: 5 }}
                    >
                      <i className="fa fa-times"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
