import _ from 'lodash';
import axios from 'axios';

import {Notify} from './services/Notify.service';

const {REACT_APP_API_BASE_URL, REACT_APP_LEGACY_API_URL} = process.env;

const $legacyFrontEndUrl = axios.create({
                                          baseURL: REACT_APP_LEGACY_API_URL,
                                          headers: {
                                            'Content-Type': 'application/json',
                                            'x-auth-token': localStorage.getItem('token')
                                          }
                                        });

// Use axios instance for Api calls where auth token IS required
const $http = axios.create({
                             baseURL: REACT_APP_API_BASE_URL,
                             headers: {'Content-Type': 'application/json'}
                           });

$http.interceptors.request.use((config) => {
  config.headers['x-auth-token'] = localStorage.getItem('token');
  return config;
});

// Use axios instance for Api calls where auth token is not required
const $publicHttp = axios.create({
                                   baseURL: REACT_APP_API_BASE_URL,
                                   headers: {'Content-Type': 'application/json'}
                                 });

export const Claims = {
  getClaims({requestData}) {
    return $http.post(`/claims/clientList`, requestData);
  },
  getClaimsTotal({requestData}) {
    return $http.post(`/claims/total`, requestData);
  },
  getCitiesAndZip() {
    return $http.get(`/claims/citiesAndZip`);
  },
  markArchive({claimIds}) {
    return $http.post(`/claims/mark-archive`, {claimIds});
  },
  exports({requestData}) {
    return $http.post(`/claims/exports`, requestData);
  },
  getById({id}) {
    return $http.get(`/claims/${id}`);
  },
  checkForDuplicates(data) {
    return $http.post(`/claims/duplicate-check`, data);
  },
  copy({id}) {
    return $http.post('/claims/copy', {id});
  },
  getMapCoordinates({id}) {
    return $http.get(`/claims/${id}/get-map-coordinates`);
  },
  CustomFields: {
    get() {
      return $http.get('/custom-fields');
    }
  },
  Vendors: {
    get() {
      return $http.get('/vendors');
    }
  },
  Insurers: {
    get() {
      return $http.get('/insurers');
    }
  },
  Attorneys: {
    get() {
      return $http.get('/attorneys');
    }
  },
  Status: {
    get() {
      return $http.get('/status?orderBy=name');
    }
  },
  Adjusters: {
    get() {
      return $http.get('/accounts/members');
    },
    createOrUpdate({claimId, claimAdjusters}) {
      return $http.post(`/adjusters/claim/${claimId}`, claimAdjusters);
    },
    getForClaim({claimId}) {
      return $http.get(`/adjusters/claim/${claimId}`);
    },
    removeFromClaim({claimId, id}) {
      return $http.delete(`/adjusters/claim/${claimId}/${id}`);
    }
  },
  Notes: {
    getForClaims({id}) {
      return $http.get(`/claims/${id}/notes`);
    },
    add({requestData, claimId}) {
      return $http.post(`/claims/${claimId}/notes`, requestData);
    },
    edit({requestData, claimId, noteId}) {
      return $http.put(`/claims/${claimId}/notes/${noteId}`, requestData);
    },
    delete({claimId, noteId}) {
      return $http.delete(`/claims/${claimId}/notes/${noteId}`);
    }
  },
  Reminders: {
    get({claimId}) {
      return $http.get(`/claims/${claimId}/reminder`);
    },
    delete({claimId, reminderId}) {
      return $http.delete(`/claims/${claimId}/reminder/${reminderId}`);
    },
    edit({claimId, reminderId, reminderData}) {
      return $http.post(
        `/claims/${claimId}/reminder/${reminderId}`,
        reminderData
      );
    },
    add({claimId, reminderData}) {
      return $http.post(`/claims/${claimId}/reminder`, reminderData);
    }
  },
  Activity: {
    get({claimId}) {
      return $http.get(`/claims/${claimId}/activity`);
    }
  },
  Attachments: {
    folder: {
      get() {
        return $http.get(`/attachments/folders`);
      },
      save({allFoldersName}) {
        return $http.post(`/attachments/folders`, allFoldersName);
      },
      getFiles({claimId, folderId}) {
        return $http.get(`/claims/${claimId}/attachments/${folderId}`);
      }
    },
    Files: {
      getSignedUrl({claimId, folderId, RequestData}) {
        const url = `/claims/${claimId}/attachments/${folderId}/signed-url/upload`;
        return $http.post(url, RequestData);
      },
      uploadOnAws({signedUrl, file}) {
        return axios.put(signedUrl, file, {
          'Content-Type': file.type
        });
      },
      download({claimId, requestData}) {
        return $http.post(
          `/claims/${claimId}/attachments/signed-url/download`,
          requestData
        );
      },
      delete({claimId, requestData}) {
        return $http.delete(`/attachments/${claimId}/delete/`, {
          data: requestData
        });
      },
      update({claimId, requestData}) {
        return $http.put(`/attachments/${claimId}/update`, requestData);
      },
      move({claimId, requestData}) {
        return $http.post(`/attachments/${claimId}/move`, requestData);
      },
      changeStatus({claimId, RequestData}) {
        return $http.post(`/claims/${claimId}/attachments/status`, RequestData);
      }
    },
    share: {
      getToken({requestData}) {
        return $http.post(`/attachments/share-file-shorten-url`, requestData);
      },
      getDownloadUrl({token}) {
        return $http.post(`/attachments/decrypt-attachment-token-create-zip`, {
          token
        });
      }
    }
  },
  Label: {
    list() {
      return $http.get(`/claims/label`);
    },
    remove({claimId}) {
      return $http.delete(`/claims/label/${claimId}`);
    },
    addToClaim({claimId, labelData}) {
      return $http.post(`/claims/label/add-to-claim/${claimId}`, labelData);
    },
    create({labelData}) {
      return $http.post(`/claims/label/`, labelData);
    },
    update({labelData}) {
      return $http.put(`/claims/label/`, labelData);
    },
    delete({labelData}) {
      return $http.delete(`/claims/label/`, {data: labelData});
    }
  },
  CustomizedFields: {
    get() {
      return $http.get(`/claims/customized-fields`);
    },
    save({data}) {
      return $http.post(`/claims/customized-fields`, data);
    }
  },
  Import: {
    saveAndGetImportId({file}) {
      const formData = new FormData();
      formData.append('file', file);
      return $http.post(`/claims/import`, formData);
    },
    getDataForMapping({id}) {
      return $http.get(`/claims/import/${id}/dataForMapping`);
    },
    mapData({id, mappedData}) {
      return $http.post(`/claims/import/${id}/dataForMapping`, mappedData);
    },
    summary({token}) {
      return $http.get(`/claims/import/${token}`);
    }
  },
  save({claimData}) {
    return $http.post(`/claims`, claimData);
  },
  edit({id, claimData}) {
    return $http.put(`/claims/${id}`, claimData);
  },
  delete({id}) {
    return $http.delete(`/claims/${id}`);
  },
  Share: {
    getToken({claimId}) {
      return $http.get(`/claims/${claimId}/share-token`);
    },
    shareToVendorAttorney({requestData}) {
      return $http.post(`/vendors/share-claims`, requestData);
    },
    shareToPolicyholders({requestData}) {
      return $http.post(`/policyholder/share-claims`, requestData);
    }
  },
  getClientCities() {
    return $http.get('/claims/cities');
  },
  getClientZips() {
    return $http.get('/claims/zips');
  }
};

export const CustomFields = {
  list() {
    return $http.get('/custom-fields');
  },
  add({requestData}) {
    return $http.post('/custom-fields', requestData);
  },
  delete({id}) {
    return $http.delete(`/custom-fields/${id}`);
  },
  edit({requestData, id}) {
    return $http.put(`/custom-fields/${id}`, requestData);
  }
};

export const Document = {
  list() {
    return $http.get(`/documents?filter=onlyList`);
  },
  get() {
    return $http.get(`/documents`);
  },
  uploadFile({file}) {
    const formData = new FormData();
    formData.append('file', file);
    return $legacyFrontEndUrl.post(`/api/uploadTemplateDoc.php`, formData);
  },
  deleteDoc({id}) {
    return $http.delete(`/documents/${id}`);
  },
  legacy: {
    getDocumentUrl({claimId, documentId}) {
      return $legacyFrontEndUrl.get(
        `/api/genratePdf.php?id=${documentId}&client=${claimId}`
      );
    },
    genrateDocumentAfterFields({claimId, documentId, requestData}) {
      return $legacyFrontEndUrl.post(
        `/api/genratePdf.php?id=${documentId}&client=${claimId}`,
        requestData
      );
    },
    downloadAsRtf({claimId, documentId, requestData}) {
      return $legacyFrontEndUrl.post(
        `/api/genratePdf.php?id=${documentId}&client=${claimId}&action=downloadAsRtf`,
        requestData
      );
    },
    saveToFolder({claimId, documentId, requestData}) {
      return $legacyFrontEndUrl.post(
        `/api/genratePdf.php?id=${documentId}&client=${claimId}&action=saveToFolder`,
        requestData
      );
    },
    uploadFile({file}) {
      const formData = new FormData();
      formData.append('file', file);
      return $legacyFrontEndUrl.post(`/api/uploadTemplateDoc.php`, formData);
    },
    getMappingTags() {
      return $legacyFrontEndUrl.get(`/api/templateMappingTags.php`);
    },
    getTagsToMap({id}) {
      return $legacyFrontEndUrl.get(`/api/getTagsToMap.php?id=${id}`);
    },
    saveMappedData({id, data}) {
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      return $legacyFrontEndUrl.post(
        `/api/saveMappedData.php?id=${id}`,
        formData
      );
    },
    genratePreview({id}) {
      return $legacyFrontEndUrl.get(
        `/api/genratePdf.php?isPreiew=true&id=${id}&client=`
      );
    }
  }
};

const INVALID_AUTH_TOKEN_ERROR = 'Invalid auth token';

export function getError(err) {
  console.error(err);
  console.error(err.response);
  const error = _.get(err, 'response.data.errors[0].msg', err.message);
  if (error === INVALID_AUTH_TOKEN_ERROR) {
    const customError = 'Login Expired';
    Notify.error(customError);
    localStorage.removeItem('token');
    //REDTAG:TJH - replace with react-router navigation
    window.open('/app/login', '_self');

    return customError;
  }
  Notify.error(error);
  return error;
}

export const getAndShowError = getError;

export function getDetailedError(err) {
  const code = _.get(err, 'response.status');
  const error = getError(err);
  return {code, error};
}

export const Vendors = {
  list() {
    return $http.get(`/vendors`);
  },
  create({vendor}) {
    return $http.post(`/vendors`, vendor);
  },
  edit({id, vendor}) {
    return $http.post(`/vendors/` + id, vendor);
  },
  delete({id}) {
    return $http.delete(`/vendors/` + id);
  }
};

export const Admins = {
  list() {
    return $http.get(`/admins`);
  },
  create({admin}) {
    return $http.post(`/admins`, admin);
  },
  edit({id, admin}) {
    return $http.post(`/admins/` + id, admin);
  },
  delete({id}) {
    return $http.delete(`/admins/` + id);
  }
};

export const Adjusters = {
  list() {
    return $http.get(`/adjusters`);
  },
  create({adjuster}) {
    return $http.post(`/adjusters`, adjuster);
  },
  edit({id, adjuster}) {
    return $http.put(`/adjusters/` + id, adjuster);
  },
  delete({id}) {
    return $http.delete(`/adjusters/` + id);
  }
};

export const Attorneys = {
  list() {
    return $http.get(`/attorneys`);
  },
  create({attorney}) {
    return $http.post(`/attorneys`, attorney);
  },
  edit({id, attorney}) {
    return $http.put(`/attorneys/` + id, attorney);
  },
  delete({id}) {
    return $http.delete(`/attorneys/` + id);
  }
};

export const Insurers = {
  list() {
    return $http.get(`/insurers`);
  },
  create({insurer}) {
    return $http.post(`/insurers`, insurer);
  },
  edit({id, insurer}) {
    return $http.post(`/insurers/` + id, insurer);
  },
  delete({id, tableName}) {
    return $http.delete(`/insurers/` + id, {data: {tableName}});
  },
  isLinkedWithClaim({insurerId}) {
    return $http.post(`/insurers/claimsInsurer`, {insurerId});
  }
};

export const Statuses = {
  list() {
    return $http.get(`/status`);
  },
  create({status}) {
    return $http.post(`/status`, status);
  },
  edit({status}) {
    return $http.put(`/status/`, status);
  },
  delete({id}) {
    return $http.delete(`/status/` + id);
  }
};

export const User = {
  getProfile() {
    return $http.get(`/users`);
  },
  settings: {
    get() {
      return $http.get(`/profile/settings`);
    },
    update({data}) {
      return $http.put(`/profile/settings`, data);
    }
  },
  updateProfile({user}) {
    return $http.post(`/profile/me`, user);
  },
  updateProfilePassword({user}) {
    return $http.post(`/profile/changePassword`, user);
  },
  uploadImage({file}) {
    return $http.post(`/profile/upload-image`, file);
  },
  removeImage({data}) {
    return $http.post(`/profile/remove-image`, data);
  }
};

export const Billing = {
  status() {
    return $http.get(`/billing/status`);
  },
  paymentHistory() {
    return $http.get(`/billing/payment-history`);
  },
  getToken() {
    return $http.get(`/billing/token`);
  },
  cancleSubscription() {
    return $http.delete(`/billing`);
  },
  retryTransaction() {
    return $http.post(`/billing/transaction`);
  },
  addCard({RequestData}) {
    return $http.post(`/billing/cards`, RequestData);
  },
  updateCard({RequestData}) {
    return $http.put(`/billing/cards`, RequestData);
  },
  getPaymentMethods() {
    return $http.get(`/billing/payment-methods`);
  },
  planBreakup() {
    return $http.get(`/billing/plan-breakup`);
  }
};

export const Calandar = {
  getAllReminders() {
    return $http.get(`/reminders/calendars`);
  },
  getMyReminders() {
    return $http.get(`/reminders/download?filter=me`);
  },
  getReminders() {
    return $http.get(`/reminders/download`);
  }
};

export const Support = {
  sendMessage({RequestData}) {
    return $http.post(`/supports`, RequestData);
  }
};

export const Auth = {
  login(credentials) {
    return $publicHttp.post(`/accounts/auth/legacy`, credentials);
  },
  getAuthUser() {
    return $http.get(`/accounts/auth`);
  },
  signup(user) {
    return $publicHttp.post(`/accounts`, user);
  },
  requestPWReset(email) {
    return $publicHttp.post(`/accounts/auth/password/reset-request`, email);
  },
  resetpassword({data}) {
    return $publicHttp.post(`/accounts/auth/password/reset`, data);
  },
  verifyAccount({token}) {
    return $publicHttp.get(`/users/verify/${token}`);
  },
  resendverificationEmail({
                            email
                          }) {
    return $publicHttp.post(`/accounts/auth/verify/`, {'email': email});
  }
};

export const Company = {
  getMembers() {
    return $http.get('/accounts/members');
  }
};
