import React, {useEffect} from 'react'
import Dropzone from 'react-dropzone'


export const MultiUploadDropzoneWithPreview = ({
    onFileDrop, 
    selectedFiles,
    setSelectedFiles,
}) => {
    const onFileDropFiles = (files) => {
        const newFilesArray = files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            showImage: true
        }))
        setSelectedFiles(newFilesArray);
        onFileDrop(newFilesArray);
    }
    
    const showDefaultImage = (ind) =>  {
        const newUplodedFile = [...selectedFiles];
        newUplodedFile[ind].showImage = false;
        setSelectedFiles(newUplodedFile);
    }
    
    useEffect(() => () => {
        selectedFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [selectedFiles]);
    
    return (
        <div>
            <div style={{textAlign: 'center'}}>
                <Dropzone 
                    onDrop={onFileDropFiles}
                    className='dropzone'
                    activeClassName='active-dropzone'
                >
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className="kt-dropzone dropzone dz-clickable" style={{minHeight: "150px"}}>
                            <input {...getInputProps()} />
                            <h3 className="sbold">Drop files here or click to upload</h3>
                            {
                                selectedFiles.length > 0 && 
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <aside style={{marginTop: "16px"}}>
                                                {
                                                    selectedFiles.map((u, ind) => {
                                                        return (
                                                            <div key={ind} style={{display: "inline-flex", borderRadius: "2px", border: "1px solid rgb(234, 234, 234)", marginBottom: "8px", marginRight: "8px", width: "100px", height: "100px", padding: "4px", boxSizing: "border-box"}}>
                                                                {
                                                                    (u.showImage) ?
                                                                        <div style={{display: "flex", overflow: "hidden"}}>
                                                                            <img onError={() => showDefaultImage(ind)} src={u.preview} alt={`Preview ${ind}`} style={{display: "block", width: "auto", height: "100%"}} />
                                                                        </div>
                                                                    :
                                                                        <div style={{textAlign: "center", width: "100%", backgroundColor: "#f7f4f4", display: "flex", overflow: "hidden"}}>
                                                                            <div style={{width: "100%", height: "100%", paddingTop: "75px"}}>
                                                                                <div style={{height: "100%", marginTop: "-79px", marginLeft: "10px", position: "absolute" }}>
                                                                                    <br/>
                                                                                    <br/>
                                                                                    <span style={{fontWeight: "600", overflow: "hidden", width: "80px", whiteSpace: "nowrap", textOverflow: "ellipsis", display: "block"}}>{u.name}</span>
                                                                                    <br/>
                                                                                    <span>{`${parseFloat(u.size/ 1000)} KB`}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </aside>
                                        </div>
                                    </div>
                            }
                            
                        </div>
                    )}
                </Dropzone>
            </div>
        </div>
    );
};