import React, { useEffect } from "react";

import { SideImage } from "./SideImage";

import { ForgotPassword } from "./forgot-password/ForgotPassword";
import { Login } from "./login/Login";
import { Signup } from "./signup/Signup";
import { VerifyAccount } from "./verify-account/VerifyAccount";
import { ResetPassword } from "./reset-password/resetPassword";
import { useBackgroundImage } from "./useBackgroundImage.hook";

import "./AuthWrapper.css";
import Logo from "../../assets/images/oa-logo.png";

const LOCATIONS = {
  login: "/login",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  verifyAccount: "/verify-account/:token",
  resetPassword: "/reset-password/:token",
};

const AuthWrapper = ({ location, history, match }) => {
  const isLoginPage = location.pathname === LOCATIONS.login;
  const isSignupPage = location.pathname === LOCATIONS.signup;
  const isForgotPasswordPage = location.pathname === LOCATIONS.forgotPassword;
  const isVerifyAccountPage = match.path === LOCATIONS.verifyAccount;
  const isResetPassword = match.path === LOCATIONS.resetPassword;

  const bgImage = useBackgroundImage();

  useEffect(() => {
    document.body.style.backgroundColor = "white";

    return () => {
      document.body.style.backgroundColor = "rgb(238, 241, 245)";
    };
  }, []);

  const getFormHeading = () => {
    switch (location.pathname) {
      case LOCATIONS.login:
        return "LOGIN";

      case LOCATIONS.signup:
        return "SIGNUP";

      case LOCATIONS.forgotPassword:
        return "Forgot Password?";

      case LOCATIONS.resetPassword:
        return "Reset New Password?";

      default:
        return "";
    }
  };

  const getHeadingButton = () => {
    switch (location.pathname) {
      case LOCATIONS.login:
        return {
          label: "Sign Up",
          onClick: () => (window.location.href = `/app${LOCATIONS.signup}`),
        };

      case LOCATIONS.signup:
        return {
          label: "Login",
          onClick: () => (window.location.href = `/app${LOCATIONS.login}`),
        };

      case LOCATIONS.forgotPassword:
        return {
          label: "Login",
          onClick: () => (window.location.href = `/app${LOCATIONS.login}`),
        };

      default:
        return {
          label: "Login",
          onClick: () => (window.location.href = `/app${LOCATIONS.login}`),
        };
    }
  };

  const headingButton = getHeadingButton();

  return (
    <div className="login">
      <div className="user-login-5">
        <div className="row bs-reset">
          <div className="col-md-6 login-container bs-reset">
            <div className="col-md-6" style={{ textAlign: "center" }}>
              <img
                className="login-logo login-6"
                style={{ width: 170 }}
                src={Logo}
                alt="logo"
              />
            </div>
            <div className="login-content">
              <h1>
                {getFormHeading()}
                <button
                  type="button"
                  className="btn blue-primary-solid pull-right"
                  onClick={headingButton.onClick}
                  data-testid={`${headingButton.label}-button`}
                >
                  {headingButton.label}
                </button>
              </h1>
              {isLoginPage && (
                <Login
                  goToForgotPassword={() =>
                    (window.location.href = `/app${LOCATIONS.forgotPassword}`)
                  }
                />
              )}
              {isSignupPage && (
                <Signup
                  goToLoginPage={() => history.push(`${LOCATIONS.login}`)}
                />
              )}

              {isForgotPasswordPage && <ForgotPassword />}
              {isVerifyAccountPage && <VerifyAccount match={match} />}
              {isResetPassword && <ResetPassword match={match} />}
            </div>
          </div>
          <div className="col-md-6 col-xs-12 bs-reset">
            <div className="login-bg">
              <SideImage bgImage={bgImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
