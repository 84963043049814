import React, { useState } from "react";

import { Button } from "./Button";
import { Icon } from "./Icons";

export const Dropdown = ({
  title,
  icon,
  variant,
  children,
  clientEdit,
  isNotOutline,
  marginLeft,
  marginRight
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isOutline = isNotOutline ? false : true;

  const handleClick = () => setIsOpen((prevState) => !prevState);
  return (
    <div className={`btn-group ${isOpen ? "open" : ""}`}>
      <Button
        isOutline={isOutline}
        variant={variant}
        onClick={handleClick}
        marginLeft={marginLeft}
        style={{
          borderBottomRightRadius: 4,
          borderTopRightRadius: 4,
        }}
      >
        {icon}
        <span className="hidden-xs"> {title} </span>
        <Icon.AngleDown />
      </Button>

      <ul
        className={marginRight ? "dropdown-menu":"dropdown-menu pull-right"}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        {children}
      </ul>
    </div>
  );
};

function DropdownListItem({ onClick, children, onlyLI }) {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(e);
  };
  return (
    <li className="bold">
      {onlyLI ? (
        onlyLI
      ) : (
        <a className="bold" href="#!" onClick={handleClick}>
          {children}
        </a>
      )}
    </li>
  );
}

function Divider() {
  return <li className="divider"> </li>;
}

Dropdown.ListItem = DropdownListItem;
Dropdown.Divider = Divider;
