import * as Api from "../../../../Api";
import { SharedClaim } from "../../../../api/sharedClaim";

import config from "../../../../config";

export function useShareAttachmentsHook({
    claimId,
    selectedFolderId,
    selectedFilesId,
    setErrorMessage,
    setIsShowSweetAlertError,
    setIsShowShareUrl,
    setShareUrl,
    isSharedView
}) {
    const { appUrl } = config;
    const shareAll = async () => {
        try{
            const requestData = {
                claimId,
                selectedFilesId: [],
                type: "all"
            };
            if(isSharedView){
                const sharedData = await SharedClaim.getAttachmentShareToken({
                    requestData
                });
                setIsShowShareUrl(true);
                const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                setShareUrl(newUrl);
            }
            if(!isSharedView){
                const sharedData = await Api.Claims.Attachments.share.getToken({
                    requestData
                });
                setIsShowShareUrl(true);
                const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                setShareUrl(newUrl);
            }

        }catch(err) {
            console.log("Getting Error into share all attachments.")
            console.log(err);
        };
    };

    const shareSelected = async () => {
        try{
            if(selectedFilesId.length > 0){
                const requestData = {
                    claimId,
                    defaultFolderId:selectedFolderId,
                    selectedFilesId: selectedFilesId,
                    type: "selected"
                };
                if(isSharedView){
                    const sharedData = await SharedClaim.getAttachmentShareToken({
                        requestData
                    });
                    setIsShowShareUrl(true);
                    const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                    setShareUrl(newUrl);
                }
                if(!isSharedView){
                    const sharedData = await Api.Claims.Attachments.share.getToken({
                        requestData
                    });
                    setIsShowShareUrl(true);
                    const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                    setShareUrl(newUrl);
                }
            }
            if(selectedFilesId.length === 0){
                setIsShowSweetAlertError(true);
                setErrorMessage("Please first select file to share.")
            }
        }catch(err) {
            console.log("Getting Error into share all attachments.")
            console.log(err);
        };
    }

    const shareSelectedFolder = async ({
        folderId
    }) => {
        try{
            const requestData = {
                claimId,
                selectedFilesId: selectedFilesId,
                type: "folder",
                defaultFolderId: folderId
            };
            if(isSharedView){
                const sharedData = await SharedClaim.getAttachmentShareToken({
                    requestData
                });
                setIsShowShareUrl(true);
                const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                setShareUrl(newUrl);
            }
            if(!isSharedView){
                const sharedData = await Api.Claims.Attachments.share.getToken({
                    requestData
                });
                setIsShowShareUrl(true);
                const newUrl = `${appUrl}/shared-claim-attachment?token=${sharedData.data.token}`;
                setShareUrl(newUrl);
            }
        }catch(err) {
            console.log("Getting Error into share all attachments.")
            console.log(err);
        };
    }

    return {
        shareAll,
        shareSelected,
        shareSelectedFolder
    }
}
