import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Form} from '../../../../_common/form/Form';
import {CurrencyMask, DatePickerMask} from '../../../../_common/MaskedInput';
import {Col, Row} from '../../../../_common/layout/Layout';
import {ClaimActions} from '../../../../../store/claims';
import {CoverageFields} from './CoverageFields';
import {AgentFields} from './AgentFields';
import {AppointmentFields} from './AppointmentFields';
import {AssignedAdjusters} from './AssignedAdjusters';
import {Attorney} from './Attorney';
import {OtherAdjusterAccess} from './OtherAdjusterAccess';
import {FeeSchedule} from './FeeSchedule';
import {DollarReceived} from './DollarReceived';

export const ClaimFieldsRight = () => {
  const dispatch = useDispatch();

  const {claimInfo: claim} = useSelector((state) => state.claims.claim.data);
  const {isSharedView} = useSelector((state) => state.claims.claim);

  const onClaimInfoChange = (data) => dispatch(ClaimActions.SetOnClaim.claimInfo(data));
  return (
    <>
      <Form.Group>
        <Row>
          <Col md={6}>
            <Form.Label>Adjuster Claim %</Form.Label>
            <Form.Input
              type="text"
              className="form-control"
              placeholder="Adjuster Claim %"
              value={claim ? claim.adjusterClaimPercentage : ''}
              onChange={(e) =>
                onClaimInfoChange({
                  name: 'adjusterClaimPercentage',
                  value: e.target.value
                })
              }
              data-testid="adjuster-claim-perc"
              disabled={isSharedView}
            />
          </Col>
          <Col md={6}>
            <Form.Label>Contract Signed</Form.Label>
            <DatePickerMask
              placeholderText={'Format as MM/DD/YYYY'}
              selectedValue={claim?.contractSigned}
              handleChange={(date) => {
                onClaimInfoChange({name: 'contractSigned', value: date});
              }}
              disabled={isSharedView}
            />
          </Col>
        </Row>
      </Form.Group>

      <CoverageFields/>

      <Form.Group>
        <Form.Label>Previously Paid</Form.Label>
        <Form.InputGroup>
          <Form.InputGroupAddon>$</Form.InputGroupAddon>
          <CurrencyMask
            className="form-control"
            placeholder="0"
            value={claim?.previouslyPaid}
            handleChange={(e) =>
              onClaimInfoChange({
                name: 'previouslyPaid',
                value: e.target.value
              })
            }
            disabled={isSharedView}
            data-testid="previously-paid"
          />
        </Form.InputGroup>
      </Form.Group>

      <DollarReceived/>

      <Form.Group>
        <Form.Label>Contract Fee</Form.Label>
        <Form.Input
          type="text"
          className="form-control"
          placeholder="Contract Fee"
          value={claim?.contractFee || ''}
          onChange={(e) =>
            onClaimInfoChange({
              name: 'contractFee',
              value: e.target.value
            })
          }
          disabled={isSharedView}
          data-testid="contract-fee"
        />
      </Form.Group>

      <FeeSchedule/>

      <AppointmentFields/>

      <AssignedAdjusters/>

      <OtherAdjusterAccess/>

      <Attorney/>

      <AgentFields/>
    </>
  );
};
