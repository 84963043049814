import React,{ useState } from "react";
import Moment from 'react-moment';
import { Error } from "../_common/Error";
import { Spinner } from "../_common/Spinner";
import { SuccessMessage } from "../_common/Sweetalert";
import * as Api from "../../Api";

export function BillingHistory({
    billingHistory,
    isLoading,
    error,
    reloadHistory, 
    reloadStatus, 
    reloadPaymentMethod
}) {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
        
    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return <Error message={error} />;
    }
    const hasHistory = billingHistory && billingHistory.length;
    if (!hasHistory) {
        return (
          <div>
            <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
                No Payment History found
            </h6>
          </div>
        );
    };

    const retryTransaction = () => {
        Api.Billing.retryTransaction().then(() => {
            reloadHistory(); 
            reloadStatus(); 
            reloadPaymentMethod();
            setShowSuccessMessage(true);
            setSuccessMessage("Charged Successfully!");
        }).catch(() => {

        })
    }

    return (
        <div className="portlet">
            <div className="portlet-body form">
                <div className="form-body">
                    <h3 className="form-section">Billing History </h3>
                    <hr style={{color: "#000000",borderTop: "1px solid #666666"}}/>
                    <table className="table table-striped t1 sortable-theme-bootstrap alignedcenter" data-sortable="">
                        <thead>
                            <tr>
                                <th style={{textAlign:"center"}}>#</th>
                                <th style={{textAlign:"center"}}><b>Billing Date</b></th>
                                <th style={{textAlign:"center"}}><b>Usage Period </b></th>
                                <th style={{textAlign:"center"}}><b># of Users</b></th>                   
                                <th style={{textAlign:"center"}}><b>Transaction Id</b></th>                   
                                <th style={{textAlign:"center"}}><b>Amount</b></th>
                                <th style={{textAlign:"center"}}><b> Payment Status</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {billingHistory.map((v, i) => {
                                    return (
                                        <tr key={i}>
                                            <td key={`index`+i} style={{textAlign:"center"}}>{i + 1}</td>
                                            <td key={`billingDate`+i} style={{textAlign:"center"}}>
                                                {<Moment format="MMM DD, YYYY">{v.transactionDate}</Moment>}
                                            </td>  
                                            <td key={`period`+i} style={{textAlign:"center"}}>{v.usagePeriod}</td>  
                                            <td key={`users`+i} style={{textAlign:"center"}}>{v.noOfUsers}</td>
                                            <td key={`transactionId`+i} style={{textAlign:"center"}}>{v.transactionId}</td>                   
                                            <td key={`amount`+i} style={{textAlign:"center"}}>{v.amount ? `$${v.amount}` : ``}</td>
                                            <td style={{textAlign:"center"}}>
                                                {
                                                    (v.transactionStatus === "PAID") ?
                                                    <span style={{backgroundColor:"green", color:"#fff", padding: "3px 10px 3px 10px", "borderRadius": "3px!important", "fontSize": "12px", "fontWeight": "bold"}}>{v.transactionStatus}</span>
                                                    : ""
                                                }
                                                {
                                                    (v.transactionStatus === "FAILED") ?
                                                    <span><span style={{backgroundColor:"#d13f3a", color:"#fff", padding: "3px 10px 3px 10px", borderRadius: "3px!important", fontSize: "12px", fontWeight: "bold"}} >{v.transactionStatus}</span>
                                                    {((i === 0)? 
                                                        <span>
                                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                            <a href="#;" onclick={() => {
                                                                retryTransaction();
                                                            }} style={{textDecoration: "underline"}}>Retry</a>
                                                        </span>
                                                        : "")}</span>
                                                    : ""
                                                }
                                                {
                                                    (v.transactionStatus === "CANCELLED") ? 
                                                        <span style={{backgroundColor:"#d13f3a", color:"#fff", padding: "3px 10px 3px 10px", borderRadius: "3px!important",fontSize: "12px", fontWeight: "bold"}} >{v.transactionStatus}</span>
                                                    : ""
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <SuccessMessage show = {showSuccessMessage} clickHandler = {setShowSuccessMessage} message = {successMessage}/>
        </div>
    );
}