import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useBillingStatus from '../../../hooks/billing/useBillingStatus';

import { ClaimForm } from "./claim-form/ClaimForm";
import { NewClaimPageBar } from "./PageBar";
import { ClaimActions } from "../../../store/claims";
import { FullPageSpinner } from "../../_common/Spinner";
import { SubscriptionModal } from "../../_common/showSubscriptionModal";

export const Claim = ({ match }) => {
  const dispatch = useDispatch();

  const newPath = "/claims/new";

  const isNew = match.path === newPath;
  const {billingStatus, userType, loading} = useBillingStatus();
  const [openPanel, setOpenPanel] = useState(false);

  useEffect(() => {
    dispatch(ClaimActions.ClaimRoute.setIsNew(isNew));
    document.title = "New Claim | Organized Adjusting";
    return () => {
      dispatch(ClaimActions.ClaimRoute.setIsNew(false));
      document.title = "Organized Adjusting";
    };
  }, [dispatch, isNew]);

  const {allowToAddNewClaim} = billingStatus || {};
  return (
    <>
      {(loading) ?
        <>
          <FullPageSpinner/>
        </>
      :
        <>
          {(allowToAddNewClaim) ?
            <div className="claim">
              <NewClaimPageBar />
              <div className="mt-10"/>
              <ClaimForm />
            </div>
            :
              <>
                <SubscriptionModal openPanel={openPanel} userType={userType} setOpenPanel={setOpenPanel}/>
              </>
          }
        </>
      }
    </>

  );
};
