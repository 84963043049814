import React from "react";
import { Error } from "../_common/Error";
import { Spinner } from "../_common/Spinner";

export function PlanSummary({ planBreakup, isLoading, error }) {
  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <Error message={error} />;
  }

  return (
    <div className="plan-breakdown">
      <div className="portlet">
        <div className="portlet-body form">
          <div className="form-body">
            <h3 className="form-section">Plan Summary</h3>
            <hr style={{ color: "#000000", borderTop: "1px solid #666666" }} />
            <table className="table table-striped t1 sortable-theme-bootstrap alignedcenter">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>#</th>
                  <th style={{ textAlign: "center" }}>
                    <b>User</b>
                  </th>
                  <th style={{ textAlign: "center" }}>
                    <b>Role</b>
                  </th>
                  <th style={{ textAlign: "center" }}>
                    <b>Details</b>
                  </th>
                  <th style={{ textAlign: "center" }}>
                    <b>Amount</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {planBreakup.users.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ textAlign: "center" }}>{i + 1}</td>
                      <td style={{ textAlign: "center" }}>{v.name}</td>
                      <td style={{ textAlign: "center" }}>{v.type}</td>
                      <td style={{ textAlign: "center" }}>
                        {v.email}
                        {v.type === "Adjuster" && v.status !== "" ? (
                          <small>({v.status})</small>
                        ) : (
                          ""
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>{v.amount}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td style={{ textAlign: "center" }}>
                    {planBreakup?.users?.length
                      ? planBreakup?.users?.length + 1
                      : planBreakup?.users?.length === 0
                      ? 1
                      : ""}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {planBreakup?.superadmin?.name}
                  </td>
                  <td style={{ textAlign: "center" }}>Super Admin</td>
                  <td style={{ textAlign: "center" }}>
                    {planBreakup?.superadmin?.email}
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    {planBreakup.basePrice}
                  </td>
                </tr>
                {planBreakup.customPrice !== "$0" && (
                  <tr>
                    <td style={{ textAlign: "center" }}>&nbsp;</td>
                    <td style={{ textAlign: "center" }}>&nbsp;</td>
                    <td style={{ textAlign: "center" }}>&nbsp;</td>
                    <td style={{ textAlign: "center", fontWeight: "bold" }}>
                      Custom Charge
                    </td>
                    <td style={{ textAlign: "center", fontWeight: "bold" }}>
                      {planBreakup.customPrice}
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ textAlign: "center" }}>&nbsp;</td>
                  <td style={{ textAlign: "center" }}>&nbsp;</td>
                  <td style={{ textAlign: "center" }}>&nbsp;</td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "2.2rem",
                    }}
                  >
                    Total
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "2.2rem",
                    }}
                  >
                    {planBreakup.total}
                  </td>
                </tr>
              </tbody>
            </table>
            {planBreakup.customPrice !== "$0" ? (
              <div class="alert alert-warning">
                <strong>Note:</strong> Billing is calculated per custom pricing
                exclusive to your account.
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
