import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Pagebar} from '../layout/Pagebar';
import {TemplatesDocDatatable} from './table';
import {useDocuments} from './templates.hook';
import {DeleteSweetAlertConfirmation} from '../_common/Sweetalert';

// NOTE:TJH - styling was explicitly requested. See
//   https://gitlab.com/alchemantic/organized-adjusting/-/issues/110#note_734789462
const MIMI_MAKEUP_TEMPLATE_TRAINING = {color: '#1059d3', fontSize: '16px'};

export function Templates() {
  const {documents, deleteTemplateDocument, getFileName, isLoading} = useDocuments();
  const history = useHistory();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteSelectedDocuments, setDeleteSelectedDocuments] = useState({});

  const breadcrumb = [
    {title: 'Home', link: '/app/claims'},
    {title: 'Manage Templates', link: ''}
  ];

  function confirmDelete(selectedDocuments) {
    setShowDeleteConfirmation(true);
    setDeleteSelectedDocuments(selectedDocuments);
  }

  function uploadTemplateDoc() {
    history.push('templates/upload');
  }

  function deleteTemplate(check) {
    if (check) {
      deleteTemplateDocument(deleteSelectedDocuments);
    }
    setShowDeleteConfirmation(false);
  }

  function handleUploadTemplate(e) {
    e.preventDefault();
    uploadTemplateDoc();
  }

  return (
    <div>
      {<Pagebar breadcrumbs={breadcrumb}/>}
      <div className="portlet light portlet-fit bordered">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase">
              Manage Templates
            </span>
          </div>
          <div className="actions">
            <button className="btn blue-primary add_new" onClick={handleUploadTemplate}>
              Upload New Template <i className="fa fa-plus"/>
            </button>
          </div>
        </div>
        <DeleteSweetAlertConfirmation show={showDeleteConfirmation} clickHandler={deleteTemplate}/>
        <div className="portlet-body">
          <div className="caption">
            <span className="caption-subject bold" style={MIMI_MAKEUP_TEMPLATE_TRAINING}>
              At no cost, we will upload your documents or train you how to easily upload yourself. Call 561-676-0212
              to change your document creation times to mere seconds. (Yes, even The Proof of Loss)
            </span>
          </div>
          <div className="table-toolbar">
            <div className="row">
              <div className="col-md-6">
                <div className="btn-group"/>
              </div>
              <div className="col-md-6"/>
            </div>
          </div>
          <div className="table-scrollable">
            <TemplatesDocDatatable documents={documents} isLoading={isLoading} getFileName={getFileName}
                                   confirmDelete={confirmDelete}/>
          </div>
        </div>
      </div>
    </div>
  );
}
