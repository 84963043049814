import React, { useState } from "react";
import { Pagebar } from "../layout/Pagebar";
import { SendMail } from "./sendMail";

export function Supports() {
  const [openSendMail, setOpenSendMail] = useState(false);

  const breadcrumb = [
    {
      title: "Home",
      link: "/app/claims",
    },
    {
      title: "Support",
      link: "",
    },
  ];

  return (
    <div>
      {<Pagebar breadcrumbs={breadcrumb} />}
      <SendMail openPanel={openSendMail} setOpenPanel={setOpenSendMail} />
      <div
        style={{
          background: "white",
          padding: "1rem 3rem",
          paddingBottom: "5rem",
          borderRadius: "4px!important",
          marginTop: "1rem",
        }}
        className="well"
      >
        <h2 style={{ fontSize: "3rem", fontWeight: "bold", color: "#012a4e" }}>
          <i
            className="fa fa-support"
            style={{ color: "#90a0af", marginRight: "10px" }}
          ></i>
          SUPPORT
        </h2>
        <p>
          Need Help? Have a suggestion? Submit a request to support for a quick
          response!
        </p>
        <button
          className="btn btn-outline blue-primary"
          onClick={() => {
            setOpenSendMail(true);
          }}
          style={{ borderRadius: "4px !important", padding: "15px 30px" }}
        >
          Submit a Support Request
        </button>
      </div>
    </div>
  );
}
