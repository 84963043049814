import React, { useEffect,useState } from "react";
import { AttachmentTabTopSection } from "./AttachmentTabTopSection";
import { useAttachments } from "./AttachmentHooks";
import jpgPng from "../../../../assets/images/client-edit/jpg.png";
import { FullPageSpinner } from "../../../_common/Spinner";

export const EmailsTab = ({
    claimId,
    folderId,
    folderName,
    selectedFiles,
    setSelectedFiles,
    isSharedView
}) => {

    const [allFilesId, setAllFilesId] = useState([]);
    const [ isLoading, setIsloading] = useState(false);

    const { folderFiles, uploadFiles,uploadOnAws, changeStatusOfFile, deleteFiles, downloadFile, reloadFolderFiles, moveFiles } = useAttachments({
        claimId,
        folderId,
        selectedFiles,
        setSelectedFiles,
        setIsloading,
        isSharedView
    });

    const selectAll = evt => {
        if(evt.target.checked){
            const allSelected = folderFiles.map((f) => {
                return f.id;
            });
            setSelectedFiles(allSelected);
        }
        if(!evt.target.checked){
            setSelectedFiles([]);
        }
    };

    const handleCheckbox = id => evt => {
        const allSelected = [...selectedFiles];
        if(evt.target.checked){
            allSelected.push(id);
            setSelectedFiles(allSelected);
        }
        if(!evt.target.checked){
            const index = allSelected.indexOf(id);
            if (index > -1) {
                allSelected.splice(index, 1);
            }
            setSelectedFiles(allSelected);
        }
    }

    const openFile = (url) => {
        window.open(url, "_blank")
    }

    useEffect(() => {
        const allSelected = folderFiles.map((f) => {
            return f.id;
        });
        setAllFilesId(allSelected);
    }, [folderFiles]);

    return (
        <div>
            {isLoading && <FullPageSpinner />}
            {!isSharedView && <AttachmentTabTopSection
                folderId={folderId}
                claimId={claimId}
                uploadFiles={uploadFiles}
                uploadOnAws = {uploadOnAws}
                changeStatusOfFile = {changeStatusOfFile}
                deleteFiles={deleteFiles}
                downloadFile={downloadFile}
                selectedFiles={selectedFiles}
                isLoading = {isLoading}
                setIsloading = {setIsloading}
                reloadFolderFiles = {reloadFolderFiles}
                moveFiles = {moveFiles}
            />}
            <table className="table table-striped table-bordered table-hover table-checkable order-column dataTable">
                <thead>
                    <tr>
                        <th>
                            <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
                                <input type="checkbox" checked={(allFilesId.length > 0 && allFilesId.sort().join(',') === selectedFiles.sort().join(',')) ? true : false} style={{height:"18px"}} className="form-control" onChange={e => selectAll(e)}/>
                                <span></span>
                            </label>
                      </th>
                      <th>Name</th>
                      <th>Uploaded By</th>
                      <th>Uploaded On</th>
                    </tr>
                  </thead>
                    {
                        (folderFiles.length === 0) &&
                            <tbody>
                                <tr>
                                    <td colSpan={4}>
                                        <div>
                                            All Your {folderName}'s Files will show here. Drop file above to add a new file.
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                    }
                    {
                        (folderFiles.length > 0) &&
                        <tbody>
                            {
                                folderFiles.map((v) => {
                                    return (
                                        <tr key={v.id}>
                                            <td>
                                                <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
                                                    <input checked={(selectedFiles.indexOf(v.id) > -1) ? true:false } type="checkbox" style={{height:"18px"}} className="form-control" value={v.id} onChange={handleCheckbox(v.id)}/>
                                                    <span></span>
                                                </label>
                                                <label></label>
                                            </td>
                                            <td>
                                                <img src={jpgPng} alt="Name" style={{verticalAlign: "middle"}}/>
                                                <a href="#!" onClick={() => openFile(v.signedUrl)} title={v.originalName} style={{color:"#61615c", textDecoration:"none"}}>
                                                    {v.originalName}
                                                </a>
                                            </td>
                                            <td>{v.username}</td>
                                            <td>{v.formatedDate}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    }
                </table>
        </div>
    );
}
