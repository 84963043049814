import React, {useRef, useState} from 'react';
import {toast} from 'react-toastify';

import addImage from '../../../../assets/images/client-edit/add_at.png';
import removeImage from '../../../../assets/images/client-edit/cross_at.png';

import * as Api from '../../../../Api';
import {Notify} from '../../../../services/Notify.service';
import {MultiUploadDropzoneWithPreview} from '../../../_common/MultiUploadDropzoneWithPreview';
import {FullPageSpinner} from '../../../_common/Spinner';
import {DeleteSweetAlertConfirmation, ErrorMessage} from '../../../_common/Sweetalert';

export function AttachmentTabTopSection(
  {
    uploadFiles, uploadOnAws, changeStatusOfFile, deleteFiles, downloadFile, selectedFiles, isLoading,
    setIsloading, reloadFolderFiles, moveFiles, folderId
  }) {

  const [userSelectedFile, setUserSelectedFile] = useState([]);
  const [isShowDropzone, setIsShowDropZone] = useState(true);
  const [isShowSweetAlertError, setIsShowSweetAlertError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const toastId = useRef(null);

  function notify(msg) {
    (toastId.current = toast(msg, {
      autoClose: 4000,
      type: toast.TYPE.SUCCESS
    }));
  }

  function update(msg) {
    toast.update(toastId.current, {
      render: msg,
      autoClose: 4000,
      type: toast.TYPE.SUCCESS
    });
  }

  function removeDropzoneFiles() {
    setUserSelectedFile([]);
  }

  async function uploadFile({files}) {
    if (files.length > 0) {
      try {
        setIsloading(true);

        const filesWithUrl = await uploadFiles({files});
        const finalStatus = [];
        await Promise.all(
          filesWithUrl.map(async (f, index) => {
            const status = await uploadOnAws(f);
            if (status.msg === 'success') {
              if (index === 0) {
                notify(`${f.file.name} uploaded`);
              } else {
                update(`${f.file.name} uploaded`);
              }

              finalStatus.push({id: f.accessId, status: 'uploadSuccessful', transition: 'none'});
            }
            if (status.msg !== 'success') {
              Notify.error(`${f.file.name} not uploaded`);
              finalStatus.push({id: f.accessId, status: 'uploadFailed'});
            }
            removeDropzoneFiles({file: f, allFiles: files});
            return f;
          })
        );
        await changeStatusOfFile({status: finalStatus});
        reloadFolderFiles();
        setIsloading(false);
      } catch (err) {
        setIsloading(false);
        Api.getError(err);
      }
    }
  }

  function deleteFilesCheck() {
    if (selectedFiles.length === 0) {
      setIsShowSweetAlertError(true);
      setErrorMessage('Please first select file to delete.');
    }
    if (selectedFiles.length > 0) {
      setShowDeleteConfirmation(true);
    }
  }

  function downloadCheck() {
    if (selectedFiles.length === 0) {
      setIsShowSweetAlertError(true);
      setErrorMessage('Please first select file to download.');
    }
    if (selectedFiles.length > 0) {
      downloadFile();
    }
  }

  function confirmDelete(e) {
    setShowDeleteConfirmation(false);
    if (e) {
      deleteFiles();
    }
  }

  function disableHandler() {
    setIsShowSweetAlertError(false);
  }

  function moveFileCheck(evt) {
    if (selectedFiles.length === 0) {
      setIsShowSweetAlertError(true);
      setErrorMessage('Please first select file to Move.');
    }
    if (
      evt.target.value !== '' &&
      Number.parseInt(evt.target.value, 10) === Number.parseInt(folderId, 10)
    ) {
      setIsShowSweetAlertError(true);
      setErrorMessage('Can\'t move files into same folder.');
    }
    if (
      evt.target.value !== ''
      && Number.parseInt(evt.target.value, 10) !== Number.parseInt(folderId, 10)
      && selectedFiles.length > 0
    ) {
      moveFiles({moveToFolder: evt.target.value});
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          {isLoading && <FullPageSpinner/>}
          {isShowSweetAlertError && (
            <ErrorMessage msg={errorMessage} show={isShowSweetAlertError} clickHandler={disableHandler}/>
          )}
          <DeleteSweetAlertConfirmation show={showDeleteConfirmation} clickHandler={confirmDelete}/>
        </div>
      </div>
      <div className="row" style={{marginBottom: '0px'}}>
        <div className="col-sm-12">
          {!isShowDropzone && (
            <a href="#!" style={{zIndex: '9'}}
               onClick={(e) => {
                 e.preventDefault();
                 setIsShowDropZone(true);
               }}>
              <img src={addImage} alt="Show Dropzone"/>
            </a>
          )}
          {isShowDropzone && (
            <a style={{zIndex: '9'}} href="#!"
               onClick={(e) => {
                 e.preventDefault();
                 setIsShowDropZone(false);
               }}>
              <img src={removeImage} alt="Hide Dropzone"/>
            </a>
          )}

          <button className="btn btn-link"
                  style={{float: 'right', padding: '10px 20px', fontWeight: 'bold', color: 'red'}}
                  onClick={deleteFilesCheck}>
            Delete
          </button>
          <button className="btn btn-link"
                  style={{float: 'right', color: '#2a80b9', padding: '10px 20px', fontWeight: 'bold'}}
                  onClick={downloadCheck}>
            Download
          </button>
          <select
            style={{
              width: '14%', marginRight: '30px', marginTop: '11px', height: '2rem', border: '1px solid #ffffff',
              float: 'right', color: '#2a80b9', fontWeight: 'bold'
            }}
            value=""
            onChange={moveFileCheck}>
            <option value="">Move</option>
            <option value="1">Documents</option>
            <option value="2">Estimates</option>
            <option value="3">Photos</option>
            <option value="4">Reports</option>
            <option value="5">Emails</option>
          </select>
        </div>
      </div>
      {isShowDropzone && (
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <MultiUploadDropzoneWithPreview selectedFiles={userSelectedFile} setSelectedFiles={setUserSelectedFile}
                                              onFileDrop={(files) => uploadFile({files})}/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
