import React from "react";
import PropTypes from "prop-types";

export function Button({
  type = "button",
  variant = "default",
  isOutline = false,
  size,
  onClick,
  children,
  classes = "",
  marginLeft,
  disabled = false
}) {
  const sizeClass =
    size === "large" ? "btn-lg" : size === "small" ? "btn-sm" : "";

  const styles = { marginLeft };

  const className = `btn ${variant} ${isOutline ? "btn-outline" : ""} ${sizeClass} ${classes}`;
  return (
    <button
      onClick={onClick}
      type={type}
      className={className}
      style={styles}
      disabled={disabled || false}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit"]),
  classes: PropTypes.string,
  isOutline: PropTypes.bool,
  variant: PropTypes.oneOf([
    "default",
    "primary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
    "red",
    "blue",
    "blue-primary",
    "blue-primary bold",
    "green",
    "yellow",
    "purple",
    "dark",
  ]),
  size: PropTypes.oneOf(["large", "small"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export function LoadingButton() {
  return (
    <button type="button" className="btn green" name="button" value="button">
      <i className="fa fa-circle-o-notch fa-spin"></i> Please Wait..
    </button>
  );
}
