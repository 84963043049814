import { useState } from "react";

import * as Api from "../../../Api";

export function useSignup() {
  const INITIAL_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    companyAddressStreet: "",
    companyAddressCity: "",
    companyAddressState: "",
    companyAddressZip: "",
    licenseNumber: "",
    password: "",
    confirmPassword: "",
    referralCode: "",
    hasConsented: false,
    role: "Super Admin",
  };
  const [formData, setFormData] = useState(INITIAL_STATE);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isDone, setIsDone] = useState(false);

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const handleSubmit = (e) => {
    e.preventDefault();

    setError("");

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords must match");
      return;
    }

    if (formData.password.length < 8) {
      setError("Password must have at least 8 characters");
      return;
    }

    if (!formData.hasConsented) {
      setError("You must agree to the terms to proceed");
      return;
    }

    signup();
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  async function signup() {
    setIsLoading(true);

    try {
      await Api.Auth.signup(formData);
      setIsDone(true);
      scrollTop();
      setFormData(INITIAL_STATE);
    } catch (err) {
      const error = Api.getError(err);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const clearIsDone = () => setIsDone(false);

  return {
    formData,
    onChange,
    isLoading,
    error,
    handleSubmit,
    isDone,
    clearIsDone,
  };
}
