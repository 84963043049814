import { useState, useEffect, useCallback } from "react";
import * as Api from "../../Api";
import Moment from "moment";

export function useClaimCalander() { 
    const [allEvents, setAllEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [initalLoading, setInitalLoading] = useState(false);

    const getCalander = useCallback(() => { 
        setInitalLoading(true);
        Api.Calandar.getAllReminders().then(async(data) => {
            // eslint-disable-next-line array-callback-return
            const allReminders = await Promise.all(data.data.reminders.map(field => { 
                return {
                    id: field.reminderId,
                    title: field.reminderName,
                    start: Moment.utc(field.reminderDate).local().format("YYYY-MM-DDTHH:mm:ss"),
                    client: {
                        name: field.clientName,
                        id: field.claimId,
                    },
                }
            }));
            setAllEvents(allReminders)
        }).catch((err) => {
            console.log("Getting error into get reminders function.")
        }).finally(() => {
            setInitalLoading(false);
        });
    },[]);

    const getMyReminders = () => {
        setIsLoading(true);
        Api.Calandar.getMyReminders().then((data) => {
            window.open(data.data.downloadUrl, "_blank");
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const getReminders = () => {
        setIsLoading(true);
        Api.Calandar.getReminders().then((data) => {
            window.open(data.data.downloadUrl, "_blank");
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => { 
        getCalander();
    },[getCalander]);
    return {
        allEvents,
        isLoading,
        setIsLoading,
        getMyReminders,
        getReminders,
        initalLoading
    }
}