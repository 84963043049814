import React, { useState, useEffect } from "react";

import { DocumentsTab } from "./DocumentsTab";
import { EstimatesTab } from "./EstimatesTab";
import { PhotosTab } from "./PhotosTab";
import { EmailsTab } from "./EmailsTab";
import { ReportsTab } from "./ReportsTab";
import { RenameFolder } from "./RenameFolders";
import * as Api from "../../../../Api";
import { SharedClaim } from "../../../../api/sharedClaim";

import "./AttachmentsFolders.css";

export const AttachmentFolders = ({
  claimId,
  setSelectedFolderId,
  selectedFilesId,
  setSelectedFilesId,
  isSharedView,
}) => {
  const [folderName, setFolderName] = useState([]);
  const [documentFolderTabView, setDocumentFolderTabView] = useState(false);
  const [estimateFolderTabView, setEstimateFolderTabView] = useState(false);
  const [photosFolderTabView, setPhotosFolderTabView] = useState(false);
  const [reportsFolderTabView, setReportsFolderTabView] = useState(false);
  const [emailsFolderTabView, setEmailsFolderTabView] = useState(false);
  const [renameFolderSection, setRenameFolderSection] = useState(false);

  const resetAllTab = () => {
    setDocumentFolderTabView(false);
    setEstimateFolderTabView(false);
    setPhotosFolderTabView(false);
    setReportsFolderTabView(false);
    setEmailsFolderTabView(false);
  };

  const loadFolderName = () => {
    Api.Claims.Attachments.folder
      .get()
      .then((data) => {
        setFolderName(data.data.folders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSharedClaimFolderName = () => {
    SharedClaim.getAttachmentFolders()
      .then((data) => {
        setFolderName(data.data.folders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFolderNameWithId = ({ id }) => {
    let obj = folderName.find((o) => o.defaultFolderId === id);
    if (obj && obj.folderName) {
      return obj.folderName;
    }
    return "";
  };

  const capitalizeFLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  };

  useEffect(() => {
    if (isSharedView) {
      loadSharedClaimFolderName();
    }
    if (!isSharedView) {
      loadFolderName();
    }
    setDocumentFolderTabView(true);
  }, [isSharedView]);

  return (
    <div>
      <RenameFolder
        openPanel={renameFolderSection}
        setOpenPanel={setRenameFolderSection}
        allFoldersName={folderName}
        setAllFolderName={setFolderName}
      />

      <ul className="nav nav-tabs client-edit-mid-tab client-edit-mid-attachment-tab">
        <li className={documentFolderTabView ? "active" : ""}>
          <a
            href="#attachments"
            onClick={() => {
              resetAllTab();
              setDocumentFolderTabView(true);
              setSelectedFolderId(1);
              setSelectedFilesId([]);
            }}
          >
            {capitalizeFLetter(
              getFolderNameWithId({
                id: 1,
              })
            )}
          </a>
        </li>
        <li className={estimateFolderTabView ? "active" : ""}>
          <a
            href="#attachments"
            onClick={() => {
              resetAllTab();
              setEstimateFolderTabView(true);
              setSelectedFolderId(2);
              setSelectedFilesId([]);
            }}
          >
            {capitalizeFLetter(
              getFolderNameWithId({
                id: 2,
              })
            )}
          </a>
        </li>
        <li className={photosFolderTabView ? "active" : ""}>
          <a
            href="#attachments"
            onClick={() => {
              resetAllTab();
              setPhotosFolderTabView(true);
              setSelectedFolderId(3);
              setSelectedFilesId([]);
            }}
          >
            {capitalizeFLetter(
              getFolderNameWithId({
                id: 3,
              })
            )}
          </a>
        </li>
        <li className={reportsFolderTabView ? "active" : ""}>
          <a
            href="#attachments"
            onClick={() => {
              resetAllTab();
              setReportsFolderTabView(true);
              setSelectedFolderId(4);
              setSelectedFilesId([]);
            }}
          >
            {capitalizeFLetter(
              getFolderNameWithId({
                id: 4,
              })
            )}
          </a>
        </li>
        <li className={emailsFolderTabView ? "active" : ""}>
          <a
            href="#attachments"
            onClick={() => {
              resetAllTab();
              setEmailsFolderTabView(true);
              setSelectedFolderId(5);
              setSelectedFilesId([]);
            }}
          >
            {capitalizeFLetter(
              getFolderNameWithId({
                id: 5,
              })
            )}
          </a>
        </li>
      </ul>

      <div className="tab-content client-edit-mid-tab-content">
        <div className={documentFolderTabView ? "tab-pane active" : "tab-pane"}>
          {documentFolderTabView && (
            <DocumentsTab
              claimId={claimId}
              folderId={1}
              folderName={getFolderNameWithId({
                id: 1,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={estimateFolderTabView ? "tab-pane active" : "tab-pane"}>
          {estimateFolderTabView && (
            <EstimatesTab
              claimId={claimId}
              folderId={2}
              folderName={getFolderNameWithId({
                id: 2,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={photosFolderTabView ? "tab-pane active" : "tab-pane"}>
          {photosFolderTabView && (
            <PhotosTab
              claimId={claimId}
              folderId={3}
              folderName={getFolderNameWithId({
                id: 3,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={reportsFolderTabView ? "tab-pane active" : "tab-pane"}>
          {reportsFolderTabView && (
            <ReportsTab
              claimId={claimId}
              folderId={4}
              folderName={getFolderNameWithId({
                id: 4,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={emailsFolderTabView ? "tab-pane active" : "tab-pane"}>
          {emailsFolderTabView && (
            <EmailsTab
              claimId={claimId}
              folderId={5}
              folderName={getFolderNameWithId({
                id: 5,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
      </div>
    </div>
  );
};
