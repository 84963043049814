import React, {useEffect, useState} from 'react';
import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import ImageDiscover from '../../assets/images/billing/discover.png';
import ImageAmericanExpress from '../../assets/images/billing/americanExpressCard.jpeg';
import ImageVisa from '../../assets/images/billing/visa2.png';
import ImageMasterCard from '../../assets/images/billing/mastercard.png';
import {Braintree, HostedField} from 'react-braintree-fields';
import * as Api from '../../Api';
import {Spinner} from '../_common/Spinner';
import {Error} from '../_common/Error';

export function AddUpdateBillingCards(
  {
    openPanel,
    setOpenPanel,
    isSubscribed,
    reloadHistory,
    reloadStatus,
    reloadPaymentMethod
  }
) {
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [error, setError] = useState('');

  const [tokenize, setTokenizeFunc] = React.useState();
  const [cardholderName, setCardHolderName] = useState('');
  const [extendedAddress, setExtendedAddress] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [locality, setLocality] = useState('');
  const [region, setRegion] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const fetchToken = () => {
    Api.Billing.getToken()
      .then((data) => {
        setToken(data.data.clientToken);
      })
      .catch((err) => {
        const error = Api.getError(err);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchToken();
  }, []);

  if (isLoading) {
    return <Spinner/>;
  }

  const addOrUpdateCard = async ({nonce}) => {
    if (
      cardholderName === '' ||
      streetAddress === '' ||
      locality === '' ||
      region === '' ||
      postalCode === ''
    ) {
      setError(
        'Invalid entry! Please check the below fields to make sure all credit card details are entered correctly.'
      );
      return false;
    }
    setError('');
    const requestData = {
      token: nonce,
      billingAddress: {
        name: cardholderName,
        streetAddress1: streetAddress,
        streetAddress2: extendedAddress,
        city: locality,
        state: region,
        zip: postalCode
      }
    };
    setIsLoadingButton(true);
    if (isSubscribed) {
      try {
        await Api.Billing.updateCard({RequestData: requestData});
        reloadHistory();
        reloadStatus();
        reloadPaymentMethod();
        setOpenPanel(false);
      } catch (e) {
        console.error(e);
        setError('Invalid Card Details.');
      } finally {
        setIsLoadingButton(false);
      }
    } else {
      try {
        await Api.Billing.addCard({RequestData: requestData});
        reloadHistory();
        reloadStatus();
        reloadPaymentMethod();
        setOpenPanel(false);
      } catch (err) {
        console.error(err);
        setError('Invalid Card Details.');
      } finally {
        setIsLoadingButton(false);
      }
    }
  };

  const handleSubmit = async () => {
    setIsLoadingButton(true);
    try {
      const {nonce} = await tokenize();
      addOrUpdateCard({nonce});
    } catch (err) {
      console.error(err);
      const {message} = err;
      setError(message);
      setIsLoadingButton(false);
    }
  };

  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-sdark uppercase">
                Update Payment Method
              </span>
            </div>
            <div className="tools">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpenPanel(false);
                }}
                href="#!"
                className="remove"
                data-original-title=""
                title=""
              >
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            <div className="row">
              <div className="col-sm-12">
                <label
                  style={{
                    fontWeight: 'bold',
                    fontSize: '15px',
                    marginTop: '10px'
                  }}
                >
                  Enter Credit Card
                </label>
              </div>
              <div className="col-sm-12" style={{textAlign: 'left'}}>
                <img src={ImageDiscover} alt="Discover"/>
                &nbsp;&nbsp;
                <img src={ImageMasterCard} alt="Master Card"/>
                &nbsp;&nbsp;
                <img
                  style={{height: '37px'}}
                  src={ImageAmericanExpress}
                  alt="American Express"
                />
                &nbsp;&nbsp;
                <img src={ImageVisa} alt="Visa"/>
              </div>
            </div>
            <div className="row">
              {error ? (
                <div className="col-sm-12">
                  <Error message={error}/>
                </div>
              ) : (
                ''
              )}
              <div className="col-sm-12">
                <Braintree
                  authorization={token}
                  getTokenRef={ref => setTokenizeFunc(() => ref)}
                  styles={{
                    input: {
                      'font-size': 'inherit'
                    },
                    ':focus': {
                      color: 'blue'
                    }
                  }}
                >
                  <div id="error_divs" style={{color: '#D13F3A'}}/>
                  <br/>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Cardholder name <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setCardHolderName(e.target.value)}
                          placeholder="Cardholder name"
                          className="form-control checkout-form"
                          style={{
                            borderRadius: '6px!important',
                            paddingTop: '5px!important',
                            paddingBottom: '5px!important',
                            fontSize: '14px'
                          }}
                          id="card-holder-name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Card Number <span className="required">*</span>
                        </label>
                        <HostedField
                          type="number"
                          className="form-control"
                          style={{
                            borderRadius: '6px!important',
                            paddingTop: '5px!important',
                            paddingBottom: '5px!important',
                            fontSize: '14px'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          CVV <span className="required">*</span>
                        </label>
                        <HostedField
                          type="cvv"
                          className="form-control"
                          style={{
                            borderRadius: '6px!important',
                            paddingTop: '5px!important',
                            paddingBottom: '5px!important',
                            fontSize: '14px'
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Expiration Date <span className="required">*</span>
                        </label>
                        <HostedField
                          type="expirationDate"
                          className="form-control"
                          style={{
                            borderRadius: '6px!important',
                            paddingTop: '5px!important',
                            paddingBottom: '5px!important',
                            fontSize: '14px'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <label
                        style={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          marginTop: '10px'
                        }}
                      >
                        Billing Address
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>
                          Street Address Line 1{' '}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setStreetAddress(e.target.value)}
                          placeholder="Street Address Line 1"
                          className="form-control checkout-form"
                          style={{
                            borderRadius: '6px!important',
                            paddingTop: '5px!important',
                            paddingBottom: '5px!important',
                            fontSize: '14px'
                          }}
                          id="street-address-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Street Address Line 2</label>
                        <input
                          type="text"
                          onChange={(e) => setExtendedAddress(e.target.value)}
                          placeholder="Street Address Line 2"
                          className="form-control checkout-form"
                          style={{
                            borderRadius: '6px!important',
                            paddingTop: '5px!important',
                            paddingBottom: '5px!important',
                            fontSize: '14px'
                          }}
                          id="street-address-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          City <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setLocality(e.target.value)}
                          placeholder="City"
                          className="form-control checkout-form"
                          style={{
                            borderRadius: '6px!important',
                            paddingTop: '5px!important',
                            paddingBottom: '5px!important',
                            fontSize: '14px'
                          }}
                          id="billing-city"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          State <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setRegion(e.target.value)}
                          placeholder="State"
                          className="form-control checkout-form"
                          style={{
                            borderRadius: '6px!important',
                            paddingTop: '5px!important',
                            paddingBottom: '5px!important',
                            fontSize: '14px'
                          }}
                          id="billing-state"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Zip Code <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setPostalCode(e.target.value)}
                          placeholder="Zip Code"
                          className="form-control checkout-form"
                          style={{
                            borderRadius: '6px!important',
                            paddingTop: '5px!important',
                            paddingBottom: '5px!important',
                            fontSize: '14px'
                          }}
                          id="postal-code"
                        />
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div
                    className="form-actions "
                    style={{paddingLeft: '0px', textAlign: 'center'}}
                  >
                    {isLoadingButton ? (
                      <button
                        disabled
                        className="btn btn-green "
                        style={{
                          width: '300px',
                          backgroundColor: 'green',
                          borderRadius: '8px!important',
                          paddingLeft: '10%',
                          paddingRight: '10%',
                          color: '#fff'
                        }}
                      >
                        {' '}
                        <i className="fa fa-circle-o-notch fa-spin"/> Please
                        Wait..{' '}
                      </button>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        className="btn btn-outline blue-primary "
                        style={{
                          width: '300px',
                          borderRadius: '8px!important',
                          paddingLeft: '10%',
                          paddingRight: '10%'
                        }}
                      >
                        {' '}
                        Update Card{' '}
                      </button>
                    )}
                  </div>
                </Braintree>
              </div>
            </div>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
