import React, {useEffect, useState} from 'react';

import * as Api from '../../Api';
import useBillingStatus from '../../hooks/billing/useBillingStatus';

import {Pagebar} from '../layout/Pagebar';
import {PlanSummary} from './planSummary';
import {BillingHistory} from './billingHistory';
import {AddUpdateBillingCards} from './addUpdateBillingCards';
import {CancelSubscription} from './cancelSubscription';
import {Spinner} from '../_common/Spinner';
import BillingImage from '../../assets/images/image (2).png';

export function Billing() {
  const {billingStatus, fetchBillingStatus} = useBillingStatus();
  const [billingHistory, setBillingHistory] = useState([]);
  const [billingHistoryLoading, setbillingHistoryLoading] = React.useState(
    true
  );
  const [billingHistoryerror, setbillingHistoryError] = React.useState('');

  const [planBreakup, setPlanBreakup] = useState([]);
  const [planBreakupLoading, setPlanBreakupLoading] = useState(true);
  const [planBreakupError, setPlanBreakupError] = useState('');

  const [paymentMethod, setPaymentMethod] = useState({});

  const [opensidePanel, setOpensidePanel] = React.useState(false);

  const [isSubscribed, setIsSubscribed] = useState(false);

  const [cancelSubscription, setCancelSubscription] = useState(false);

  const fetchBillingHistory = async () => {
    try {
      const data = await Api.Billing.paymentHistory();
      setBillingHistory(data.data);
    } catch (err) {
      console.error(err);
      const error = Api.getError(err);
      setbillingHistoryError(error);
    } finally {
      setbillingHistoryLoading(false);
    }
  };

  const fetchPlanBreakup = () => {
    Api.Billing.planBreakup()
      .then((data) => {
        setPlanBreakup(data.data);
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].msg
        ) {
          setPlanBreakupLoading(false);
          setPlanBreakupError(err.response.data.errors[0].msg);
        }
      })
      .finally(() => setPlanBreakupLoading(false));
  };

  const fetchPaymentMethods = async () => {
    try {
      const data = await Api.Billing.getPaymentMethods();
      setPaymentMethod(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchBillingHistory();
    fetchPlanBreakup();
    fetchPaymentMethods();
  }, []);

  const hasBillingStatus = billingStatus && billingStatus.plan;
  if (!hasBillingStatus) {
    return <Spinner/>;
  }

  const breadcrumb = [
    {
      title: 'Home',
      link: '/app/claims'
    },
    {
      title: 'Billing',
      link: ''
    }
  ];

  return (
    <div>
      {<Pagebar breadcrumbs={breadcrumb}/>}
      <div>
        {billingStatus.customerId === '' && (
          <div>
            <h1>Billing </h1>
            <hr style={{color: '#000000', borderTop: '1px solid #666666'}}/>
            <div className="row">
              <div className="col-sm-6 centerAlignOnSmallScreen">
                <p
                  style={{
                    marginBottom: '0px',
                    fontWeight: 'bold',
                    fontFamily: 'Lato,sans-serif!important'
                  }}
                >
                  First 3 Files Free
                </p>
                <p style={{marginTop: '0px!important'}}>
                  For Unlimited Claims Files
                </p>
                <p
                  style={{
                    margin: '0px!important',
                    color: 'green',
                    fontSize: '25px',
                    fontWeight: 'bold'
                  }}
                ></p>
                <p style={{borderRadius: '24px!important'}}>
                  <button
                    onClick={() => {
                      setOpensidePanel(true);
                      setIsSubscribed(false);
                    }}
                    style={{
                      width: '300Px',
                      borderRadius: '10px!important',
                      fontSize: '20px',
                      fontWeight: 'bold'
                    }}
                    className="btn btn-outline blue-primary"
                  >
                    Subscribe Now
                  </button>
                </p>
                <p style={{fontStyle: 'italic'}}>
                  To change your pricing add/remove{' '}
                  <a href="/app/admins" target="_blank">
                    Admins
                  </a>{' '}
                  or{' '}
                  <a target="_blank" href="/app/adjusters">
                    Adjusters
                  </a>{' '}
                  from your account.
                </p>
              </div>
              <div className="col-sm-6 centerAlignOnSmallScreen">
                <div
                  style={{textAlign: 'right'}}
                  className="centerAlignOnSmallScreen"
                >
                  <img
                    style={{width: '75%'}}
                    src={BillingImage}
                    alt="Billing"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {billingStatus.customerId !== '' && (
          <div>
            <h1>
              Billing
              {billingStatus.subscriptionStatus === 'Active' ||
              billingStatus.subscriptionStatus === 'Suspended' ? (
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#fff',
                    backgroundColor: 'Green',
                    padding: '4px',
                    borderRadius: '4px!important'
                  }}
                >
                  SUBSCRIPTION ACTIVE
                </span>
              ) : (
                ''
              )}
            </h1>
            <hr style={{color: '#000000', borderTop: '1px solid #666666'}}/>
            <div className="row">
              <div className="col-sm-6 centerAlignOnSmallScreen">
                {billingStatus.subscriptionStatus !== 'Canceled' ? (
                  <p
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '0px',
                      fontFamily: 'Lato,sans-serif!important'
                    }}
                  >
                    Next Bill on {billingStatus.plan.nextBillingDate}
                  </p>
                ) : (
                  ''
                )}
                {billingStatus.subscriptionStatus === 'Canceled' ? (
                  <div>
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '0px',
                        fontFamily: 'Lato,sans-serif!important'
                      }}
                    >
                      First 3 Files Free
                    </p>
                    <p
                      style={{
                        marginTop: '0px',
                        color: '#d13f3a',
                        fontStyle: 'italic'
                      }}
                    >
                      Your subscription was cancelled on{' '}
                      {billingStatus.subscriptionCancelDate}.
                    </p>
                  </div>
                ) : (
                  ''
                )}
                {billingStatus.subscriptionStatus !== 'Canceled' ? (
                  <div>
                    <p>
                      {billingStatus.subscriptionStatus === 'Suspended' ? (
                        <span
                          style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: '#fff',
                            backgroundColor: '#d13f3a',
                            padding: '4px',
                            borderRadius: '4px!important'
                          }}
                        >
                          PAYMENT METHOD FAILED
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                ) : (
                  ''
                )}
                {billingStatus.subscriptionStatus === 'Canceled' ? (
                  <div>
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '0px',
                        fontFamily: 'Lato,sans-serif!important'
                      }}
                    >
                      For Unlimited Claims Files.
                    </p>

                    <p style={{borderRadius: '24px!important'}}>
                      <button
                        onClick={() => {
                          setOpensidePanel(true);
                          setIsSubscribed(false);
                        }}
                        style={{
                          width: '300Px',
                          borderRadius: '10px!important',
                          fontSize: '20px',
                          fontWeight: 'bold'
                        }}
                        className="btn btn-outline blue-primary"
                      >
                        Subscribe Now
                      </button>
                    </p>
                    <p style={{fontStyle: 'italic'}}>
                      To change your pricing add/remove{' '}
                      <a href="/app/admins" target="_blank">
                        Admins
                      </a>{' '}
                      or{' '}
                      <a target="_blank" href="/app/adjusters">
                        Adjusters
                      </a>{' '}
                      from your account.
                    </p>
                  </div>
                ) : (
                  ''
                )}
                {billingStatus.subscriptionStatus !== 'Canceled' ? (
                  <div>
                    {paymentMethod.last4 ? (
                      <p>
                        Billed To Your Credit Card.
                        <br/>
                        {paymentMethod.imageUrl ? (
                          <img
                            style={{width: '40px'}}
                            src={paymentMethod.imageUrl}
                            alt="Payment method"
                          />
                        ) : (
                          ''
                        )}
                        {paymentMethod.last4 ? (
                          billingStatus.subscriptionStatus === 'Suspended' ? (
                            <span style={{color: '#D13F3A'}}>
                              ending in {paymentMethod.last4}
                            </span>
                          ) : (
                            <span>ending in {paymentMethod.last4}</span>
                          )
                        ) : (
                          ''
                        )}{' '}
                        &nbsp;
                        <a
                          style={{textDecoration: 'underline'}}
                          href="#;"
                          onClick={(e) => {
                            e.preventDefault();
                            setOpensidePanel(true);
                            setIsSubscribed(true);
                          }}
                        >
                          Update
                        </a>
                      </p>
                    ) : (
                      ''
                    )}
                    <p>
                      If You Need To Cancel
                      <br/>
                      <a
                        href="#;"
                        onClick={() => {
                          setCancelSubscription(true);
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: '#d13f3a'
                        }}
                        id="cancelSubscription"
                      >
                        Cancel Subscription
                      </a>
                    </p>
                    <p style={{fontStyle: 'italic'}}>
                      To change your pricing add/remove{' '}
                      <a href="/app/admins" target="_blank">
                        Admins
                      </a>{' '}
                      or{' '}
                      <a target="_blank" href="/app/adjusters">
                        Adjusters
                      </a>{' '}
                      from your account.
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="col-sm-6 centerAlignOnSmallScreen">
                <div
                  style={{textAlign: 'right'}}
                  className="centerAlignOnSmallScreen"
                >
                  <img
                    style={{width: '75%'}}
                    src={BillingImage}
                    alt="Billing"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <CancelSubscription
        openPanel={cancelSubscription}
        setOpenPanel={setCancelSubscription}
        reloadHistory={fetchBillingHistory}
        reloadStatus={fetchBillingStatus}
        reloadPaymentMethod={fetchPaymentMethods}
      />
      <AddUpdateBillingCards
        isSubscribed={isSubscribed}
        openPanel={opensidePanel}
        setOpenPanel={setOpensidePanel}
        reloadHistory={fetchBillingHistory}
        reloadStatus={fetchBillingStatus}
        reloadPaymentMethod={fetchPaymentMethods}
      />
      {
        <PlanSummary
          planBreakup={planBreakup}
          isLoading={planBreakupLoading}
          error={planBreakupError}
        />
      }
      {
        <BillingHistory
          billingHistory={billingHistory}
          isLoading={billingHistoryLoading}
          error={billingHistoryerror}
          reloadHistory={fetchBillingHistory}
          reloadStatus={fetchBillingStatus}
          reloadPaymentMethod={fetchPaymentMethods}
        />
      }
    </div>
  );
}
