import React, {useEffect, useState} from 'react';

import * as Api from '../../Api';
import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import {Error} from '../_common/Error';
import {PhoneNumberMask} from '../_common/MaskedInput';
import {SubmitButton} from '../profile/SubmitButton';

function validateEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const INITIAL_ADMIN_STATE = {name:'', email:'', phone:'', phoneExt:'', fax:'', status:''};

export function AddNewAndEdit({openPanel, setOpenPanel, adminDetails, reloadTable}) {
  const [adminState, setAdminState] = useState(adminDetails || INITIAL_ADMIN_STATE)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const {name, email, phone, phoneExt, fax, status} = adminState;

  useEffect(() => {setAdminState(adminDetails || INITIAL_ADMIN_STATE)}, [setAdminState, adminDetails]);

  async function handleSubmit(event) {
    event.preventDefault();

    if (!name) {
      setError('Name is required');
      return false;
    }
    if (!email) {
      setError('Email is required');
      return false;
    }
    if (!validateEmail(email)) {
      setError('Email Should be valid.');
      return false;
    }
    if (!status) {
      setError('Status is required');
      return false;
    }
    setIsLoading(true);
    try {
      if (adminState.id) {
        await Api.Admins.edit({id: adminState.id, admin: adminState});
      } else {
        await Api.Admins.create({admin: adminState});
      }
      setAdminState(INITIAL_ADMIN_STATE);
      reloadTable();
      setOpenPanel(false);
      setError('');
    } catch (err) {
      if (err.response
        && err.response.data
        && err.response.data.errors[0]
        && err.response.data.errors[0].msg) {
        setIsLoading(false);
        setError(err.response.data.errors[0].msg);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function closePanel(e) {
    e.preventDefault();
    setOpenPanel(false);
    setError('');
    setAdminState(INITIAL_ADMIN_STATE);
  }

  function handleUpdate(field) {
    return ({target}) =>
      setAdminState({...adminState, [field]: target.value});
  }

  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-sdark uppercase">
                {adminState.id ? 'Edit Admin Details' : 'Add New Admin'}
              </span>
            </div>
            <div className="tools">
              <a className="remove" title="" href="#!" data-original-title="" onClick={closePanel}>
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            {error ? <Error message={error}/> : ''}

            <form onSubmit={handleSubmit} method="post">
              <div className="form-group">
                <label className="control-label">Admin Name</label>
                <input className="form-control" type="text" name="adminName" placeholder="Admin Name" value={name}
                       data-testid="admin-name" onChange={handleUpdate('name')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Email Address</label>
                <input className="form-control" type="text" name="adminEmail" placeholder="Email Address" value={email}
                       data-testid="admin-email" onChange={handleUpdate('email')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Phone #</label>
                <PhoneNumberMask placeholderText="Phone #" value={phone} handleChange={handleUpdate('phone')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Extension</label>
                <input className="form-control" type="text" name="adminExtension" placeholder="Extension"
                       value={phoneExt} data-testid="admin-ext" onChange={handleUpdate('phoneExt')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Fax #</label>
                <PhoneNumberMask placeholderText="Fax #" value={fax} handleChange={handleUpdate('fax')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Status</label>
                <select className="form-control" data-testid="status" value={status}
                        onChange={handleUpdate('status')}>
                  <option value="">--SELECT--</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="form-actions">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <SubmitButton isLoading={isLoading}/>
                    <button className="btn default" style={{margin: 5}} type="button" name="cancel" value="cancel"
                            onClick={closePanel}>
                      <i className="fa fa-times"/> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
