import React from "react";
import { useSelector } from "react-redux";
import groupBy from "lodash.groupby";
import { Link, useRouteMatch } from "react-router-dom";
import {version} from "../../../package.json"

import "./Sidebar.css";

import { OPTION_TYPES, options } from "./SidebarOptions";

const Sidebar = ({ isResponsiveShow, user }) => {
  const { role } = user || {};
  const groups = groupBy(
    options.filter((o) => o.allowedRoles.includes(role)),
    "groupTitle"
  );

  const { isCollapsed } = useSelector((state) => state.theme.sidebar);

  const { url } = useRouteMatch();

  React.useEffect(() => {
    if (isCollapsed) {
      document.body.classList.add("page-sidebar-closed");
    } else {
      document.body.classList.remove("page-sidebar-closed");
    }
  }, [isCollapsed]);

  const sidebarStyle = {
    overflowY: "scroll",
    height: "100vh",
    paddingBottom: 100,
    width: isResponsiveShow ? "auto" : 234,
    paddingTop: "20px",
  };

  const getOptionItem = (item) => {
    switch (item.type) {
      case OPTION_TYPES.heading:
        return <h3 className="uppercase">{item.title}</h3>;

      case OPTION_TYPES.internalLink:
        return (
          <Link to={`${item.link}`} className="nav-link nav-toggle">
            <span className="option-title" data-testid={item.dataTestId}>
              {item.title}
            </span>
            {item.isActive ? <span className="selected" /> : ""}
            <span className="arrow" />
          </Link>
        );

      case OPTION_TYPES.externalLink:
        return (
          <a href={`${item.link}`} className="nav-link nav-toggle">
            <span className="option-title" data-testid={item.dataTestId}>
              {item.title}
            </span>
            {item.isActive ? <span className="selected" /> : ""}
            <span className="arrow" />
          </a>
        );

      default:
        return null;
    }
  };

  return (
    <div className="page-sidebar-wrapper">
      <div
        className={`page-sidebar navbar-collapse collapse ${
          isResponsiveShow ? "in" : ""
        }`}
      >
        <ul
          className={`page-sidebar-menu page-header-fixed page-sidebar-menu-light text-left margin-bottom-20 ${
            isCollapsed ? "page-sidebar-menu-closed" : ""
          }`}
          style={sidebarStyle}
        >
          {Object.entries(groups).map(([key, value]) => (
            <React.Fragment key={key}>
              {value.map((optionItem) => (
                <li
                  className={
                    optionItem.type === "heading"
                      ? "heading"
                      : url === optionItem.link
                      ? "nav-item active"
                      : "nav-item"
                  }
                  key={optionItem.id}
                >
                  {getOptionItem(optionItem)}
                </li>
              ))}
            </React.Fragment>
          ))}
          <li className="build-version" style={{}}>
            <small>Build Version: {version}</small>
          </li>
          <div className="spacer mb-50"></div>
        </ul>
      </div>
    </div>
  );
};

export { Sidebar };
