import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Header } from "../layout/Header";
import { Sidebar } from "../layout/Sidebar";
import { Pagebar } from "../layout/Pagebar";
import "../../assets/css/layouts/css/layout.min.css";
import "../../assets/css/layouts/css/themes/darkblue.min.css";

import { AuthActions } from "../../store/auth";

const PrivateRoute = ({
  location: { pathname },
  component: Component,
  isAuthenticated,
  isLoading,
  rolesAllowed,
  alerts,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [isResponsiveShow, setIsResponsiveShow] = useState(false);

  const toggleResponsiveShow = () => setIsResponsiveShow(!isResponsiveShow);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(AuthActions.GetAuthUser.start());
  }, [dispatch]);

  const { role } = user ?? "";

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem("token")) {
          console.info(
            "Private Route: Redirecting user as they are not authenticated"
          );
          return <Redirect to="/login" />;
        }
        // check if forbidden path
        const adjusterRoles = ["Adjuster", "adjuster", "2"];
        const forbiddenPaths = [
          "/billing",
          "/admins",
          "/calendar",
          "/archive-files",
          "/adjusters",
        ];

        if (adjusterRoles.includes(role) && forbiddenPaths.includes(pathname)) {
          return <Redirect to="/claims" />;
        }
        // user has the required role
        return (
          <Fragment>
            <Header toggleResponsiveShow={toggleResponsiveShow} user={user} />
            <div className="clearfix" />
            <div className="page-container">
              <Sidebar isResponsiveShow={isResponsiveShow} user={user} />
              <div className="page-content-wrapper">
                <div
                  className="page-content"
                  style={{ minHeight: "100vh", background: "#eef1f5" }}
                >
                  <Pagebar />
                  <div className="App-content">
                    <Component {...props} />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  alerts: PropTypes.array,
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
};

export { PrivateRoute };
