import { ClaimTypes as Types, ClaimInitialState } from "./index";

function updateStateWithCustomFields({ customFields, isFetched, state }) {
  if (!isFetched) {
    return {
      ...state,
      claim: {
        ...state.claim,
        data: {
          ...state.claim.data,
          customFields: customFields,
        },
      },
    };
  }

  return {
    ...state,
    claim: {
      ...state.claim,
      data: {
        ...state.claim.data,
        customFields: customFields,
      },
      lastSavedData: {
        ...state.claim.lastSavedData,
        customFields: customFields,
      },
    },
  };
}

export default function ClaimsReducer(
  state = ClaimInitialState.INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case Types.CLEAR_CLAIM:
      return ClaimInitialState.INITIAL_STATE;
    case Types.CLAIM_ROUTE.SET_IS_NEW:
      return {
        ...state,
        claim: {
          ...state.claim,
          isNew: payload,
        },
      };

    case Types.CLAIM_ROUTE.SET_IS_SHARED_VIEW:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSharedView: payload,
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.SET_IS_SAVE_AND_CLOSE:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaveAndClose: payload,
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.SET_IS_FORM_SUBMITTED:
      return {
        ...state,
        claim: {
          ...state.claim,
          isFormSubmitted: payload,
        },
      };

    case Types.SET_ON_CLAIM.VALUE:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            [payload.name]: payload.value,
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.CLIENT:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            client: {
              ...state.claim.data.client,
              [payload.name]: payload.value,
            },
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.CLIENT1:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            client: {
              ...state.claim.data.client,
              residence: {
                ...state.claim.data.client.residence,
                client1: {
                  ...state.claim.data.client.residence.client1,
                  [payload.name]: payload.value,
                },
              },
            },
          },
          hasUnsavedChanges: true,
        },
      };
    case Types.SET_ON_CLAIM.CLIENT2:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            client: {
              ...state.claim.data.client,
              residence: {
                ...state.claim.data.client.residence,
                client2: {
                  ...state.claim.data.client.residence.client2,
                  [payload.name]: payload.value,
                },
              },
            },
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.CONTACT:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            client: {
              ...state.claim.data.client,
              contact: {
                ...state.claim.data.client.contact,
                [payload.contactType]: {
                  ...state.claim.data.client.contact[payload.contactType],
                  [payload.name]: payload.value,
                },
              },
            },
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.PROPERTY:
      const updatedProperty = {
        ...state.claim.data?.client?.property,
        ...(payload.type === "field"
          ? { [payload.name]: payload.value }
          : payload),
      };
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            client: {
              ...state.claim.data?.client,
              property: updatedProperty,
              ...(state.claim.data?.client?.isMailingSameAsProperty && {
                mailing: updatedProperty,
              }),
            },
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.MAILING:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            client: {
              ...state.claim.data.client,
              mailing: {
                ...state.claim.data.client.mailing,
                ...(payload.type === "field"
                  ? { [payload.name]: payload.value }
                  : { ...payload }),
              },
            },
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.NEW_CLAIM_ADJUSTER:
      return {
        ...state,
        claim: {
          ...state.claim,
          claimAdjusters: {
            ...state.claim.claimAdjusters,
            list: [...state.claim.claimAdjusters.list, {}],
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.DELETE_CLAIM_ADJUSTER:
      return {
        ...state,
        claim: {
          ...state.claim,
          claimAdjusters: {
            ...state.claim.claimAdjusters,
            list: state.claim.claimAdjusters.list.filter(
              (adj, index) => index !== payload
            ),
          },
          hasUnsavedChanges: false,
        },
      };

    case Types.SET_ON_CLAIM.CLAIM_ADJUSTER:
      return {
        ...state,
        claim: {
          ...state.claim,
          claimAdjusters: {
            ...state.claim.claimAdjusters,
            list: state.claim.claimAdjusters.list.map((adjuster, index) =>
              index === payload.index
                ? {
                    ...adjuster,
                    [payload.name]: payload.value,
                  }
                : adjuster
            ),
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.CLAIM_INFO:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            claimInfo: {
              ...state.claim.data.claimInfo,
              [payload.name]: payload.value,
            },
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.VENDOR:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            vendor: {
              ...state.claim.data?.vendor,
              [payload.name]: payload.value,
            },
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.ATTORNEY:
        return {
          ...state,
          claim: {
            ...state.claim,
            data: {
              ...state.claim.data,
              attorney: {
                ...state.claim.data?.attorney,
                [payload.name]: payload.value,
              },
            },
            hasUnsavedChanges: true,
          },
        };

    case Types.SET_ON_CLAIM.ASSIGNED_ADJUSTERS:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            assignedAdjusters: payload,
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.OTHER_ADJUSTERS_ALLOWED:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            otherAdjustersAccess: payload,
          },
          hasUnsavedChanges: true,
        },
      };

    case Types.SET_ON_CLAIM.CUSTOM_FIELDS:
      return updateStateWithCustomFields({
        customFields: payload.customFields,
        isFetched: payload.isFetched,
        state,
      });

    case Types.CREATE_OR_UPDATE_CLAIM.START:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaving: true,
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.IS_CREATING:
      return {
        ...state,
        claim: {
          ...state.claim,
          isCreating: true,
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.CANCEL:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaving: false,
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          id: payload.id,
          isSaving: false,
          hasUnsavedChanges: false,
          lastSavedAt: payload.lastSavedAt,
          lastSavedData: { ...state.claim.data },
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.ERROR:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaving: false,
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.CLAIM_ADJUSTERS_START:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaving: true,
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.CLAIM_ADJUSTERS_SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaving: false,
          hasUnsavedChanges: false,
          lastSavedAt: payload.lastSavedAt,
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.CLAIM_ADJUSTERS_CANCEL:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaving: false,
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.CLAIM_ADJUSTERS_ERROR:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaving: false,
          hasUnsavedChanges: true,
        },
      };

    case Types.CLAIM_ADJUSTERS.GET_START:
      return {
        ...state,
        claim: {
          ...state.claim,
          claimAdjusters: {
            ...state.claim.claimAdjusters,
            isLoading: true,
          },
        },
      };

    case Types.CLAIM_ADJUSTERS.GET_SUCCESS:
      function formatClaimAdjusters(stateAdjusters, apiAdjusters) {
        const hasStateAdjusters = stateAdjusters.filter(
          (adjuster) => Object.values(adjuster).length
        ).length;

        const hasApiAdjusters = apiAdjusters && apiAdjusters.length;

        if (!hasApiAdjusters) {
          return stateAdjusters;
        }

        if (!hasStateAdjusters) {
          return apiAdjusters;
        }

        return stateAdjusters.map((adjuster, index) => ({
          ...adjuster,
          id: apiAdjusters[index]?.id,
        }));
      }
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaving: false,
          hasUnsavedChanges: false,
          claimAdjusters: {
            ...state.claim.claimAdjusters,
            list: formatClaimAdjusters(
              state.claim.claimAdjusters.list,
              payload
            ),
            isLoading: false,
            lastSavedData: [...state.claim.claimAdjusters.list],
          },
        },
      };
    case Types.CLAIM_ADJUSTERS.GET_ERROR:
      return {
        ...state,
        claim: {
          ...state.claim,
          isSaving: false,
          hasUnsavedChanges: false,
          claimAdjusters: {
            isLoading: false,
            ...state.claim.claimAdjusters,
          },
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.DUPLICATE_CHECK_START:
      return {
        ...state,
        claim: {
          ...state.claim,
          duplicateClients: {
            isLoading: true,
            list: [],
            hasFetched: false,
          },
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.DUPLICATE_CHECK_SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          duplicateClients: {
            isLoading: false,
            list: payload.duplicates,
            allowDuplicateStatus: Types.ALLOW_DUPLICATE_STATUSES.UNSPECIFIED,
            hasFetched: true,
            checkedForParams: payload.checkedForParams,
          },
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.DUPLICATE_SET_ALLOW:
      return {
        ...state,
        claim: {
          ...state.claim,
          duplicateClients: {
            ...state.claim.duplicateClients,
            allowDuplicateStatus: payload,
          },
        },
      };

    case Types.CREATE_OR_UPDATE_CLAIM.CLEAR_DUPLICATES:
      return {
        ...state,
        claim: {
          ...state.claim,
          duplicateClients: {
            ...state.claim.duplicateClients,
            list: [],
          },
        },
      };

    case Types.GET_CLAIM.START:
      return {
        ...state,
        claim: {
          ...state.claim,
          isLoading: true,
        },
      };

    case Types.GET_CLAIM.SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          isLoading: false,
          data: payload,
          id: payload.client.id,
          lastSavedData: { ...payload },
        },
      };

    case Types.GET_SHARED_CLAIM.START:
      return {
        ...state,
        claim: {
          ...state.claim,
          isLoading: true,
        },
      };

    case Types.GET_SHARED_CLAIM.SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          isLoading: false,
          data: payload.claim,
          id: payload.claim.client.id,
          claimAdjusters: {
            ...state.claim.claimAdjusters,
            list: payload.claimAdjusters,
          },
        },
      };

    case Types.GET_SHARED_CLAIM.CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            customFields: payload,
          },
        },
      };

    case Types.GET_CLAIM.ERROR:
    case Types.GET_SHARED_CLAIM.ERROR:
      return {
        ...state,
        claim: {
          ...state.claim,
          isLoading: false,
          error: {
            code: payload.code,
            message: payload.message,
            description: payload.description,
          },
        },
      };

    case Types.GET_CLAIM.ADJUSTERS_SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          claimAdjusters: {
            ...state.claim.claimAdjusters,
            isLoading: false,
            list: payload,
          },
        },
      };

    case Types.CLAIM_ADJUSTERS.DELETE_START:
      return {
        ...state,
        claim: {
          ...state.claim,
          claimAdjusters: {
            ...state.claim.claimAdjusters,
            isDeleting: true,
          },
        },
      };

    case Types.CLAIM_ADJUSTERS.DELETE_SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          claimAdjusters: {
            ...state.claim.claimAdjusters,
            isDeleting: false,
            hasUnsavedChanges: payload?.hasUnsavedChanges,
            lastSavedAt: payload?.lastSavedAt,
          },
        },
      };

    default:
      return state;
  }
}
