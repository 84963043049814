import React from "react";
import { Portlet } from "../../../_common/portlets/Portlet";
import { ClientType } from "./ClientType";
import { useClaim } from "../claim.hooks";
import { ClientContact } from "./ClientContact";
import { PropertyAddress } from "./PropertyAddress";
import { MailingAddress } from "./MailingAddress";
import { Form } from "../../../_common/form/Form";
import { ClaimAdjusters } from "./adjusters/ClaimAdjusters";
import { ClaimInfo } from "./claim-fields/ClaimInfo";
import { ClaimActions } from "./ClaimActions";

import "./ClaimForm.css";
import { CustomFields } from "./custom-fields/CustomFields";

export const ClaimForm = () => {
  const { handleSubmit, isLoading } = useClaim();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit({ isSaveAndClose: false });
  };

  return (
    <Portlet isLoading={isLoading} isBox classColor="light mt-10">
      <Form onSubmit={handleFormSubmit}>
        <ClaimActions onSubmit={handleSubmit} />

        <Form.SectionHeading>Client Info</Form.SectionHeading>
        <ClientType onSubmit={handleSubmit} />
        <ClientContact />
        <PropertyAddress />
        <MailingAddress />

        <Form.SectionHeading>Claim Info</Form.SectionHeading>
        <ClaimAdjusters />

        <Form.SectionHeading>&nbsp;&nbsp;</Form.SectionHeading>
        <ClaimInfo />

        <CustomFields />
      </Form>
    </Portlet>
  );
};
