import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import {Form} from '../../../../_common/form/Form';
import {CurrencyMask, DatePickerMask} from '../../../../_common/MaskedInput';
import {ClaimActions} from '../../../../../store/claims';
import {VendorFields} from './VendorFields';

export const ClaimFieldsLeft = () => {
  const dispatch = useDispatch();
  const statuses = useSelector((state) => state.statuses.list);
  const {claimInfo: claim} = useSelector((state) => state.claims.claim.data);
  const {isSharedView} = useSelector((state) => state.claims.claim);

  const selectedStatusId = claim?.status;

  const onClaimInfoChange = (data) => dispatch(ClaimActions.SetOnClaim.claimInfo(data));

  const handlePolicyStartDateChange = (date) => {
    let endDate;
    if (date) {
      endDate = moment(date).add(1, 'year');
    }

    onClaimInfoChange({
      name: 'policyStartDate',
      value: date
    });
    onClaimInfoChange({
      name: 'policyEndDate',
      value: endDate
    });
  };

  return (
    <>
      <Form.Group>
        <Form.Label>Claim Number</Form.Label>
        <Form.Input
          placeholder="Claim Number"
          value={claim ? claim.claimNumber : ''}
          onChange={(e) => onClaimInfoChange({name: 'claimNumber', value: e.target.value})}
          type="text"
          disabled={isSharedView}
          data-testid="claim-number"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Policy Number</Form.Label>
        <Form.Input
          placeholder="Policy Number"
          value={claim ? claim.policyNumber : ''}
          onChange={(e) => onClaimInfoChange({name: 'policyNumber', value: e.target.value})}
          type="text"
          disabled={isSharedView}
          data-testid="policy-number"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Date of Loss </Form.Label>
        <DatePickerMask
          placeholderText={'Format as MM/DD/YYYY'}
          selectedValue={claim?.dateOfLoss}
          handleChange={(date) => {
            onClaimInfoChange({name: 'dateOfLoss', value: date});
          }}
          disabled={isSharedView}
          data-testid="date-of-loss"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Cause of Loss</Form.Label>
        <Form.Input
          placeholder="Cause of Loss"
          value={claim ? claim.causeOfLoss : ''}
          onChange={(e) =>
            onClaimInfoChange({
              name: 'causeOfLoss',
              value: e.target.value
            })
          }
          type="text"
          disabled={isSharedView}
          data-testid="cause-of-loss"
        />
      </Form.Group>

      <Form.Group data-testid="date-called-in-form-group">
        <Form.Label>Date Called In </Form.Label>
        <DatePickerMask
          placeholderText={'Format as MM/DD/YYYY'}
          selectedValue={claim?.dateCalledIn}
          handleChange={(date) => {
            onClaimInfoChange({
              name: 'dateCalledIn',
              value: date
            });
          }}
          disabled={isSharedView}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Deductible</Form.Label>
        <div className="input-group">
          <div className="input-group-addon">$</div>
          <CurrencyMask
            className="form-control"
            placeholder="0"
            value={claim?.deductible}
            handleChange={(e) => {
              onClaimInfoChange({
                name: 'deductible',
                value: e.target.value
              });
            }}
            disabled={isSharedView}
            data-testid="deductible"
          />
        </div>
      </Form.Group>

      <Form.Group>
        <Form.Label>Settlement Date</Form.Label>
        <DatePickerMask
          selectedValue={claim?.settlementDate}
          handleChange={(date) => {
            onClaimInfoChange({
              name: 'settlementDate',
              value: date
            });
          }}
          disabled={isSharedView}
          placeholderText={'Format as MM/DD/YYYY'}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Status</Form.Label>
        {isSharedView ? (
          <Form.Input value={selectedStatusId} disabled data-testid="status"/>
        ) : (
          <Form.Select
            value={selectedStatusId}
            onChange={(e) =>
              onClaimInfoChange({
                name: 'status',
                value: e.target.value
              })
            }
            disabled={isSharedView}
            data-testid="status"
          >
            <option>--Select--</option>
            {statuses?.map((status) => (
              <option key={status.id} value={status.statusName.toLowerCase()}>
                {status.statusName}
              </option>
            ))}
          </Form.Select>
        )}
      </Form.Group>

      <VendorFields/>

      <Form.Group>
        <Form.Label>Policy Start Date</Form.Label>
        <DatePickerMask
          placeholderText={'Format as MM/DD/YYYY'}
          selectedValue={claim?.policyStartDate}
          handleChange={handlePolicyStartDateChange}
          disabled={isSharedView}
          data-testid="policy-start-date"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Policy End Date</Form.Label>
        <DatePickerMask
          placeholderText={'Format as MM/DD/YYYY'}
          selectedValue={claim?.policyEndDate}
          handleChange={(date) => {
            onClaimInfoChange({
              name: 'policyEndDate',
              value: date
            });
          }}
          disabled={isSharedView}
          data-testid="policy-end-date"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Mortgage Name</Form.Label>
        <Form.Input
          placeholder="Mortgage Name"
          value={claim ? claim.mortgageName : ''}
          onChange={(e) =>
            onClaimInfoChange({
              name: 'mortgageName',
              value: e.target.value
            })
          }
          type="text"
          disabled={isSharedView}
          data-testid="mortgage-name"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Mortgage Address</Form.Label>
        <Form.Input
          placeholder="Mortgage Name"
          value={claim ? claim.mortgageAddress : ''}
          onChange={(e) =>
            onClaimInfoChange({
              name: 'mortgageAddress',
              value: e.target.value
            })
          }
          type="text"
          disabled={isSharedView}
          data-testid="mortgage-address"
        />
      </Form.Group>
    </>
  );
};
