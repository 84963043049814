import React from 'react';

import {useSignup} from './useSignup.hooks';
import {BRAND_NAME} from '../../../config';

import {SignupSuccess} from './SignupSuccess';
import {PhoneNumberMask} from '../../_common/MaskedInput';

function Error({message, dataTestId = 'error-alert'}) {
  return (
    <div style={{background: '#ffebe7', padding: 0.5, marginBottom: 10}}>
      <p style={{color: 'tomato', textAlign: 'center'}} data-testid={dataTestId}>
        {message}
      </p>
    </div>
  );
}

export const Signup = ({goToLoginPage}) => {
  const {formData, onChange, isLoading, error, handleSubmit, isDone, clearIsDone} = useSignup();

  if (isDone) {
    return (
      <SignupSuccess clearIsDone={clearIsDone} goToLoginPage={goToLoginPage}/>
    );
  }

  return (
    <>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input"
                   type="text" name="firstName" autoComplete="off" placeholder="First Name" required
                   value={formData.firstName} onChange={onChange}/>
          </div>
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input"
                   type="text" name="lastName" autoComplete="off" placeholder="Last Name" required
                   value={formData.lastName} onChange={onChange}/>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input"
                   type="email" name="email" autoComplete="off" placeholder="Email" required
                   value={formData.email} onChange={onChange}/>
          </div>
          <div className="col-xs-12 col-md-6">
            <PhoneNumberMask inputClass="form-control form-control-solid placeholder-no-fix form-group login-input"
                             name="phone" placeholderText="Phone Number" value={formData.phone}
                             handleChange={onChange} required/>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input"
                   type="text" name="companyName" autoComplete="off" placeholder="Company Name" required
                   value={formData.companyName} onChange={onChange}/>
          </div>

          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input"
                   type="text" name="licenseNumber" autoComplete="off" placeholder="License Number" required
                   value={formData.licenseNumber} onChange={onChange}/>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group  login-input"
                   type="password" name="password" autoComplete="off" placeholder="Password" required
                   aria-required="true" aria-invalid="false" value={formData.password} onChange={onChange}/>
          </div>
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group  login-input"
                   type="password" name="confirmPassword" autoComplete="off" placeholder="Retype Password" required
                   aria-required="true" aria-invalid="false" value={formData.confirmPassword} onChange={onChange}/>
          </div>
        </div>

        <h4>Company Address</h4>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input"
                   type="text" name="companyAddressStreet" autoComplete="off" placeholder="Street Address" required
                   value={formData.companyAddressStreet} onChange={onChange}/>
          </div>
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input"
                   type="text" name="companyAddressCity" autoComplete="off" placeholder="City" required
                   value={formData.companyAddressCity} onChange={onChange}/>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input"
                   type="text" name="companyAddressState" autoComplete="off" placeholder="State" required
                   value={formData.companyAddressState} onChange={onChange}/>
          </div>
          <div className="col-xs-12 col-md-6">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input" type="text"
                   name="companyAddressZip" autoComplete="off" placeholder="Zip code" required
                   value={formData.companyAddressZip} onChange={onChange}/>
          </div>
        </div>

        <h4>Referral Code</h4>
        <p>
          If you were referred by a {BRAND_NAME} sales rep, please enter your
          code to receive additional discounts.
        </p>

        <div className="row">
          <div className="col-xs-12 col-md-12">
            <input className="form-control form-control-solid placeholder-no-fix form-group login-input"
                   type="text" name="referralCode" autoComplete="off" placeholder="Referral Code (optional)"
                   aria-required="true" aria-invalid="false" value={formData.referralCode} onChange={onChange}/>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group margin-top-50 margin-bottom-20">
              <label className="mt-checkbox mt-checkbox-outline">
                <input type="checkbox" name="hasConsented" checked={formData.hasConsented}
                       data-testid="consent-checkbox"
                       onChange={(e) =>
                         onChange({target: {name: 'hasConsented', value: !formData.hasConsented}})
                       }/>
                {' '}I agree to {BRAND_NAME}'s{' '}
                <a target="_blank" rel="noopener noreferrer" href="/app/terms-and-conditions" data-testid="terms-link">
                  <b>
                    <u>Terms &amp; Conditions</u>
                  </b>{' '}
                </a>{' '}
                and{' '}
                <a target="_blank" rel="noopener noreferrer" href="/app/privacy-policy" data-testid="privacy-link">
                  <b>
                    <u>Privacy Policy</u>
                  </b>
                </a>
                <span/>
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12" style={{position: 'relative'}}>
            {error && <Error message={error}/>}
          </div>
          <div className="col-md-12">
            <div>
              <button className="btn dark btn-block btn-lg" type="submit" disabled={isLoading}>
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 text-center" style={{maginTop: '2em'}}>
            <div className="forgot-password" style={{marginTop: '2em'}}>
              <button onClick={goToLoginPage} className="forget-password" type="button" data-testid="login-link">
                Already have an account? Go to Login
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
